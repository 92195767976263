import { adminConstants } from "../../constants";
import axios from "../axiosConfig";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";


export const getCorpData = () => async (dispatch) => {
  const response = await trackPromise(
    axios.get(`admin/corp/detail/`)
  );
  dispatch({
    type: adminConstants.GET_ADMIN_ORGANIZATIONS,
    payload: response.data.data,
  });
};

export const updateCorpData = (input) => async dispatch => {
  try {
      const response = await trackPromise(axios.put(`admin/corp/update`, input));
      toast.success('Organization detail is updated.');

      dispatch({
          type: adminConstants.ACTION_UPDATE_ORGANIZATION,
          payload: response.data.data
      });
  } catch (error) {
      toast.error('Error occurred while saving Organization detail');
  }
};
