import axios from "../axiosConfig";
import {userConstants} from "../../constants";
import {trackPromise} from "react-promise-tracker";

export const actionGetActivityLog = () => async dispatch => {
    const response = await trackPromise(axios.get(`audit`));
    dispatch({
        type: userConstants.ACTION_GET_ACTIVITY_LOG,
        payload: response.data.data
    });

};
