import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
//import DatePicker from "react-datepicker";
import DateTimePicker from 'react-datetime-picker';
import {CustomDatePicker} from "../../../../../shared/custom-date-picker";
import {useSelector} from "react-redux";

export const SupervisorCommentControl = (props) => {

    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const [punchInDateTime, setPunchInDateTime] = useState();
    const [punchOutDateTime, setPunchOutDateTime] = useState();
    const [showDatePickers, setShowDatePickers] = useState(false);

    const {attendanceDetail} = useSelector(state => state.user.attendanceReducer);

    const {editingComment} = props;


    useEffect(() => {
        if (editingComment) {
            setPunchInDateTime(new Date(editingComment.punchInDateTime));
            setPunchOutDateTime(new Date(editingComment.punchOutDateTime));
            setComment(editingComment.comment);
        } else {
            setPunchInDateTime(new Date(attendanceDetail.changeRequest.requestTime.punchInDateTime));
            setPunchOutDateTime(new Date(attendanceDetail.changeRequest.requestTime.punchOutDateTime));
        }

    }, [attendanceDetail, editingComment]);


    const responseToChangeRequest = (status) => {
        if (status !== 'approved' && !comment) {
            setCommentError(true);
            return false;
        } else if (status === 'approved') {
            setPunchInDateTime(new Date(attendanceDetail.changeRequest.requestTime?.punchInDateTime));
            setPunchOutDateTime(new Date(attendanceDetail.changeRequest.requestTime?.punchOutDateTime));
        }

        const data = {
            status,
            comment,
            punchInDateTime,
            punchOutDateTime
        };

        if (editingComment) {
            data._id = editingComment._id;
        }

        props.handleSuperVisorResponse(data);
        setComment("");
        setCommentError(false);
        setShowDatePickers(false);
    };


    return (
        <>
            <hr/>
            <Row>
                <Col md={6}>
                    <label>Comments</label>
                    <textarea rows={3} className="form-control" value={comment}
                              onChange={(e) => setComment(e.target.value)}/>
                    {commentError && <small className="text-danger">Comments are required</small>}
                </Col>
            </Row>
            <Row className="mt-3">
                {!showDatePickers ? (
                    <Col md={3}>

                        <Button variant="success" size="sm" className="mr-1"
                                onClick={() => responseToChangeRequest('approved')}>Approve</Button>
                        <Button variant="info" size="sm" className="mr-1"
                                onClick={() => setShowDatePickers(true)}>Modify</Button>
                        <Button variant="danger" size="sm" className="mr-1"
                                onClick={() => responseToChangeRequest('rejected')}>Reject</Button>
                    </Col>
                ) : (
                    <>
                        <Col md={3}>
                            <label>PunchIn Time</label>
                            <DateTimePicker
                                formate="dd-MM-YYYY HH:mm:ss "
                                maxDate={new Date()}
                                value={punchInDateTime}
                                onChange={date => setPunchInDateTime(date)}
                                customInput={<CustomDatePicker/>}
                                //showDatePickers
                                // showTimeSelectOnly
                                // timeIntervals={5}
                                // showDatePickers
                                //setShowDatePickers
                                // timeCaption="Time"
                            />
                        </Col>
                        <Col md={3}>
                            <label>PunchOut Time</label>
                            <DateTimePicker
                                formate="DD-MM-YYYY HH:mm:ss"
                                maxDate={new Date()}
                                value={punchOutDateTime}
                                onChange={date => setPunchOutDateTime(date)}
                                customInput={<CustomDatePicker/>}
                                // showTimeSelect
                                // showTimeSelectOnly
                                // timeIntervals={5}
                                // timeCaption="Time"
                                // setShowDatePickers
                            />
                        </Col>
                        <Col xs="12">
                            <Button size="sm" className="mr-1" variant="outline-secondary"
                                    onClick={() => setShowDatePickers(false)}>Cancel</Button>
                            <Button size="sm" className="mr-1" variant="outline-primary"
                                    onClick={() => responseToChangeRequest("modified")}>Save</Button>
                        </Col>
                    </>
                )
                }
            </Row>
        </>
    );
};
export const SupervisorBtCommentControl = (props) => {
    const responseToChangeRequest = (status) => {
        const data = {
            status,
            breakTimeId: props.currentBreakTimeId
        };
        props.submitBtChangeRequest(data);
    };


    return (
        <>
            <hr/>

            <Col md={6}>

                <Button variant="success" size="sm" className="mr-1"
                        onClick={() => responseToChangeRequest('approved')}>Approve</Button>
                <Button variant="danger" size="sm" className="mr-1"
                        onClick={() => responseToChangeRequest('rejected')}>Reject</Button>
            </Col>

        </>
    );
};
