import {adminConstants} from "../../constants";

const INITIAL_STATE = {
    attendanceList: [],
    V_AttendanceRecords: [],
    Ack_AttendanceRecords: [],
    selectedAttendance: {},
    attendance: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConstants.GET_PENDING_ACK_ATTENDANCES:
            return {
                ...state,
                Ack_AttendanceRecords: action.payload
            };
        case adminConstants.ACTION_ADMIN_ACK_EMPLOYEE_ATTENDANCE:
            const {Ack_AttendanceRecords} = state;
            const updatedAttendanceList = Ack_AttendanceRecords.map(attendance => attendance._id === action.payload._id ? action.payload : attendance);
            return {
                ...state,
                Ack_AttendanceRecords: updatedAttendanceList,
                selectedAttendance: action.payload
            };
        case adminConstants.GET_ATTENDANCE_LIST:
            return {
                ...state,
                V_AttendanceRecords: action.payload
            };
        case adminConstants.GET_ATTENDANCE_ONE:
            return {
                ...state,
                attendance: action.payload
            }
        case adminConstants.SET_SELECTED_ATTENDANCE:
            return {
                ...state,
                selectedAttendance: action.payload
            };
        case adminConstants.ACTION_APPROVE_ATTENDANCE_RECORD:
            return {
                ...state,
                V_AttendanceRecords: [].concat(state.V_AttendanceRecords.map(attendance => attendance._id === action.payload._id ? action.payload : attendance))
            };
        default:
            return state;
    }
}
