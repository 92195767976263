import React from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AttendanceVerificationOptions from "./ARVO_Fields";
import {Button, Card} from "react-bootstrap";
import {connect} from "react-redux";
import {actionUpdateOrganization} from "../../../../../actions/organization.actions";
import {toast} from "react-toastify";

class AttendanceSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            attendanceAcknowledgementRequired: false
        }
    }

    componentDidMount() {
        const {attendanceAcknowledgementRequired} = this.props.selectedOrganization;
        this.setState({attendanceAcknowledgementRequired});
    }

    onClickUpdateOrganizationSettings = async () => {
        const {selectedOrganization} = this.props;
        try {
            await this.props.actionUpdateOrganization({
                _id: selectedOrganization._id,
                attendanceAcknowledgementRequired: this.state.attendanceAcknowledgementRequired
            });
            toast.success('Attendance Settings are updated');
        } catch (e) {

        }
    };

    render() {

        const {attendanceAcknowledgementRequired} = this.state;
        return (
            <div className="pt-3">
                <h4>Attendance Settings</h4>
                <Tabs defaultActiveKey="ARV_Options" id="uncontrolled-tab-example">
                    <Tab eventKey="ARV_Options" title="Attendance Record Verification Options">
                        <AttendanceVerificationOptions/>
                    </Tab>
                    <Tab eventKey="" title="Other">
                        <Card>
                            <Card.Body>
                                <Card.Title></Card.Title>
                                <label>
                                    <input type="checkbox" className="mr-1" checked={attendanceAcknowledgementRequired}
                                           onChange={e => this.setState({attendanceAcknowledgementRequired: e.target.checked})}/>
                                    <span>Employees attendance acknowledgement is required</span>
                                </label>
                                <br/>
                                <Button
                                    size="sm"
                                    onClick={() => this.onClickUpdateOrganizationSettings()}>
                                    Save
                                </Button>
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            </div>

        );
    }
}

const mapStateToProps = ({organizationReducer}) => ({
    selectedOrganization: organizationReducer.selectedOrganization
});

export default connect(mapStateToProps, {actionUpdateOrganization})(AttendanceSettings);


