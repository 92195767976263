import {adminConstants} from "../../constants";
import axios from '../axiosConfig';
import {toast} from 'react-toastify';
import {trackPromise} from 'react-promise-tracker';

export const getDashboardCount = () => async dispatch => {
    const response = await trackPromise(axios.get(`admin/dashboard/count`));
    
    dispatch({
        type: adminConstants.GET_DASHBOARD_DATA,
        payload: response.data.data
    })
}