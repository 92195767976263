import React from "react";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../utlis";
import {userConstants} from "../../../../../constants";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import {AttendanceAcknowledgment} from "../../../shared/attendance/attendance-acknowledgement";
import {connect} from "react-redux";
import {
    acknowledgeAttendance, acknowledgeCompleteAttendance, actionGetAckAttendanceList,
    actionGetPendingAcknowledgeAttendanceList, setSelectedAttendance
} from "../../../../../actions/admin/attendance.actions";

class AttendanceAcknowledgementRecords extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAttendanceAcknowledgeModal: false,
        };
    }

    componentDidMount() {
        this.props.actionGetAckAttendanceList(this.props.status);
    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                selector: 'user.firstName',
                sortable: true,
                cell: row => row.user.firstName + ' ' + row.user.lastName
            },
            {
                name: lang.Outlet,
                selector: 'outlet.name',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                sortable: true,
                selector: 'punchInDateTime',
                cell: row => convertDateTimeToCustomFormat(row.punchInDateTime)
            },
            {
                name: lang.PunchOutTime,
                sortable: true,
                selector: 'punchOutDateTime',
                cell: row => (
                    row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    const endTime = row.punchOutDateTime ? row.punchOutDateTime : (new Date()).toISOString();
                    return calculateTimeDiff(endTime, row.punchInDateTime)
                }
            }

        ]
    };
    handleClosing = () => {
        this.setState({showAttendanceAcknowledgeModal: false})
        this.props.actionGetAckAttendanceList(this.props.status)
    }

    render() {
        const {lang, attendanceList, selectedAttendance, title} = this.props;
        const {showAttendanceAcknowledgeModal} = this.state;

        return (
            <div className="pt-3 rdt_approval_photo">
                <DataTable
                    title={title}
                    columns={this.renderColumns()}
                    data={attendanceList}
                    pagination={true}
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={(row) => {
                        this.props.setSelectedAttendance(row);
                        this.setState({showAttendanceAcknowledgeModal: true})
                    }}
                />
                <AttendanceAcknowledgment
                    attendance={selectedAttendance}
                    showModal={showAttendanceAcknowledgeModal}
                    handleModalClose={() => this.handleClosing()}
                    canAcknowledge={true}
                    handleSubmitAcknowledgement={this.props.acknowledgeAttendance}
                    handleBulkAcknowledgement={this.props.acknowledgeCompleteAttendance}
                    lang={lang}/>
            </div>
        );
    }
}

const mapStateToProps = ({langReducer, admin: {attendanceReducer}}) => ({
    lang: langReducer.lang,
    attendanceList: attendanceReducer.Ack_AttendanceRecords,
    selectedAttendance: attendanceReducer.selectedAttendance
});

export default connect(mapStateToProps, ({
    actionGetPendingAcknowledgeAttendanceList,
    actionGetAckAttendanceList,
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    setSelectedAttendance
}))(AttendanceAcknowledgementRecords);
