import React from 'react';
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../../utlis";
import {userConstants} from "../../../../../../constants";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import {useSelector} from "react-redux";
import Card from "react-bootstrap/Card";

export const PendingChangeRequestsList = (props) => {

    const {lang} = useSelector(state => state.langReducer);
    const {data} = props;

    const calculateTotalTime = (record) => {
        const startTime = record.ifLate ? record.changeRequest?.adjustedTime.punchInDateTime : record.punchInDateTime;
        const endTime = record.punchOutDateTime ? record.ifLate ? record.changeRequest?.adjustedTime.punchOutDateTime : record.punchOutDateTime : (new Date()).toISOString();
        return calculateTimeDiff(endTime, startTime);
    };
    const customStyles = {
        header: {
            style: {
                minHeight: '50px',
                width: 'auto'
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',

            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',

                },
            },
        },
        cells: {
            style: {

                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                },
            },
        },
    };

    const renderColumns = () => {
        return [
            {
                name: lang.Name,
                selector: 'user.firstName',
                sortable: true,
                cell: row => <span className="font-weight-bold">{row.user.firstName}</span>
            },
            {
                name: lang.Outlet,
                selector: 'outlet.name',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
                cell: row => {
                    if (!row.ifLate) {
                        return convertDateTimeToCustomFormat(row.punchInDateTime)
                    } else {
                        return convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchInDateTime)
                    }
                }
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                cell: row => {
                    if (row.ifLate) {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchOutDateTime) : '-----'

                    } else {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                    }
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => calculateTotalTime(row)
            },
        ];
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Pending Change Requests</Card.Title>
                <DataTable
                    customStyles={customStyles}
                    columns={renderColumns()}
                    data={data}
                    pagination={true}
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={props.navigateToAttendanceDetail}
                />
            </Card.Body>
        </Card>

    );
};
