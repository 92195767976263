import {userConstants} from "../../constants";

const INITIAL_STATE = {
    outlets: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case userConstants.ACTION_GET_USER_OUTLETS:
            return {
                ...state,
                outlets: action.payload
            };
        default:
            return {...state};
    }
}
