import React, {useEffect, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTimePicker from 'react-datetime-picker';
import {CustomDatePicker} from "../../../../../shared/custom-date-picker";
import {useSelector} from "react-redux";
import Button from "react-bootstrap/Button";

export const ChangeTimes = (props) => {
    const [punchInDateTime, setPunchInDateTime] = useState(new Date());
    const [punchOutDateTime, setPunchOutDateTime] = useState(new Date());
    const [comments, setComments] = useState('');
    const [disabled, setDisabled] = useState(false)

    const {attendanceDetail} = useSelector(state => state.user.attendanceReducer);
    const {loggedInUser} = useSelector(state => state.authentication);


    useEffect(() => {
        if (!attendanceDetail.punchOutDateTime) {
            setPunchOutDateTime(null);
            setDisabled(true);
            setPunchInDateTime(new Date(attendanceDetail.punchInDateTime));

        } else {
            setPunchInDateTime(new Date(attendanceDetail.punchInDateTime));
            setPunchOutDateTime(new Date(attendanceDetail.punchOutDateTime));


        }
    }, [attendanceDetail]);


    const handleSubmit = () => {
        const data = {
            user: attendanceDetail.user._id,
            attendance: attendanceDetail._id,
            requestTime: {
                punchInDateTime,
                punchOutDateTime,
            },
            adjustedTime: {
                punchInDateTime: attendanceDetail.punchInDateTime,
                punchOutDateTime: attendanceDetail.punchOutDateTime,
            },
            comments: [{
                punchInDateTime,
                punchOutDateTime,
                comment: comments,
                status: 'pending',
                user: loggedInUser._id
            }],
            lastStatus: 'pending'
        };

        props.submitChangeTimeRequest(data);
        setComments('')

    };

    return (
        <>
            <Row className="form-group">
                <Col md={3}>
                    <label>New PunchIn Time</label>
                    <DateTimePicker
                        format="dd-MMM-yyyy HH:mm:ss"
                        maxDate={new Date()}
                        value={punchInDateTime}
                        onChange={date => setPunchInDateTime(date)}
                        customInput={<CustomDatePicker/>}

                        timeCaption="Time"
                    />
                </Col>
                <Col md={3}>
                    <label>New PunchOut Time</label>
                    <DateTimePicker
                        format="dd-MMM-yyyy h:mm:ss"
                        maxDate={new Date()}
                        disabled={disabled ? true : false}
                        value={punchOutDateTime}
                        onChange={date => setPunchOutDateTime(date)}
                        customInput={<CustomDatePicker/>}
                    />
                </Col>
                <Col md={6}>
                    <label>Comment</label>
                    <input className="form-control" onChange={e => setComments(e.target.value)}/>
                </Col>
                <Col>
                    <Button size={'sm'} variant="outline-secondary" className="mr-1"
                            onClick={props.cancelChangeReq}>Cancel</Button>
                    <Button size={'sm'} variant="outline-primary"
                            onClick={() => handleSubmit()}>Submit</Button>
                </Col>
            </Row>

        </>
    )
};
export const ChangeBreakTime = (props) => {
    const [requestedBreakStartTime, setrequestedBreakStartTime] = useState(new Date());
    const [requestedBreakEndTime, setrequestedBreakEndTime] = useState(new Date());
    const [disabled, setDisabled] = useState(false)

    const {attendanceDetail} = useSelector(state => state.user.attendanceReducer);
    const {loggedInUser} = useSelector(state => state.authentication);

    useEffect(() => {


    }, [attendanceDetail]);


    const handleBtSubmit = () => {
        const data = {
            user: attendanceDetail.user._id,
            attendance: attendanceDetail._id,
            requestedBreakStartTime,
            requestedBreakEndTime,

            status: 'pending'
        };

        props.submitBtChangeRequest(data);
    };
    return (
        <>

            <Col md={3}>
                <label>New start Time</label>
                <DateTimePicker
                    format="dd-MMM-yyyy HH:mm:ss"
                    maxDate={new Date()}
                    value={requestedBreakStartTime}
                    onChange={date => setrequestedBreakStartTime(date)}
                    customInput={<CustomDatePicker/>}

                    timeCaption="Time"
                />
            </Col>
            <Col md={3}>
                <label>New End Time</label>
                <DateTimePicker
                    format="dd-MMM-yyyy h:mm:ss"
                    maxDate={new Date()}
                    disabled={props.disabled ? true : false}
                    value={props.disabled ? null : requestedBreakEndTime}
                    onChange={date => setrequestedBreakEndTime(date)}
                    customInput={<CustomDatePicker/>}
                />
            </Col>
            <br></br>
            <Col md={9}>
                <Button size={'sm'} variant="outline-secondary" className="mr-1"
                        onClick={props.cancelChangeReq}>Cancel</Button>
                <Button size={'sm'} variant="outline-primary"
                        onClick={() => handleBtSubmit()}>Submit</Button>
            </Col>


        </>
    )
}
