import React from 'react';

export const CustomDatePicker = (props) => {

    const {value, onClick} = props;

    return (
        <div className="input-group mb-3" onClick={onClick}>
            <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1"><i className="far fa-calendar-alt"/></span>
            </div>
            <button className="form-control text-left">{value}</button>
        </div>
    )
};
