import React from 'react';
import styled from 'styled-components';
import {storiesOf} from '@storybook/react';
import DataTable from "react-data-table-component";

    const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

    const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  
  color:white;
`;


    const FilterComponent = ({filterText, onFilter, onClear}) => (
        <>
            <TextField className="form-control w-25"  id="search" type="text" placeholder="Filter By name" aria-label="Search Input"
                       value={filterText} onChange={onFilter}/>
            <ClearButton className="btn btn-primary"  type="button" onClick={onClear}>X</ClearButton>
        </>
    );

  export  const BasicTable = (props) => {
        const [filterText, setFilterText] = React.useState('');
        const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
        const filteredItems = props?.attendanceList?.filter(item => item?.user?.firstName && item?.user?.firstName.toLowerCase().includes(filterText.toLowerCase()) || item?.user?.lastName && item?.user?.lastName .toLowerCase().includes(filterText.toLowerCase()));

        const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };

            return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                                    filterText={filterText}/>;
        }, [filterText, resetPaginationToggle]);

        return (
            <DataTable
                title={props.title}
                columns={props.columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                pointerOnHover
                onRowClicked={props.showAttendanceDetailsModal}
            />
        );
    };
    storiesOf('Filtering', module)
        .add('Example 1', () => <BasicTable/>);

