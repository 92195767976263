import axios from "../axiosConfig";
import {trackPromise} from "react-promise-tracker";
import {toast} from "react-toastify";
import {adminConstants} from "../../constants";

export const actionCreateNewContent = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`admin/contents/create`, data));
        toast.success('New content is created.');
        dispatch({
            type: adminConstants.CREATE_CONTENT,
            payload: response.data.data
        });
    } catch (e) {

    }
};

export const actionGetContents = () => async dispatch => {
    const response = await trackPromise(axios.get(`admin/contents/list`));
    dispatch({
        type: adminConstants.GET_CONTENTS,
        payload: response.data.data
    });
};

export const actionGetOneContent = (contentId) => async dispatch => {
    const response = await trackPromise(axios.get(`admin/contents/list/${contentId}`));
    dispatch({
        type: adminConstants.GET_ONE_CONTENT,
        payload: response.data.data
    });
};
