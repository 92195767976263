import React, {useState} from 'react';
import {Card, Col, Container, Form, Row, InputGroup, FormControl, Button} from "react-bootstrap";
import './Login.css';

function Login(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const navigateToDashboard = () => {
        props.navigate('/home/dashboard');
    };

    return (
        <Container>
            <Row>
                <Col md={{span: 6, offset: 3}} className="align-self-center">
                    <h1 className="text-center font-weight-bold mt-5">Attendance</h1>
                    <Card>
                        <small className="text-center mt-5 mb-3">Sign in to start your session</small>
                        <Form className="mx-5" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Enter username or email"
                                        aria-label="Username or Email"
                                        aria-describedby="username-addon"
                                        required
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="username-addon">
                                            <i className="fas fa-user"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Enter password"
                                        aria-label="Password"
                                        aria-describedby="password-addon"
                                        required
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="password-addon">
                                            <i className="fas fa-lock"/>
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Button variant="primary" block type="button"
                                        onClick={() => navigateToDashboard}>Login</Button>
                                <small style={{cursor: "pointer"}} className="text-info" onClick={() => window.open(`${process.env.REACT_APP_URL}/forgetpassword`)}>Forgot password?</small>
                            </Form.Group>

                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
