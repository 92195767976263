import React, {useRef} from "react";
import JoditEditor from "jodit-react";

export const TextEditor = (props) => {
    const editorRef = useRef(null);
    const {config, value, onBlur} = props;

    const defaultConfig = {
        readonly: false,
        spellcheck: true,
        showXPathInStatusbar: false,
        uploader: {
            insertImageAsBase64URI: true
        },
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,
        placeholder: ""
    };

    return (
        <JoditEditor
            ref={editorRef}
            value={value}
            config={config || defaultConfig}
            tabIndex={0}
            onBlur={onBlur}

        />
    );
};
