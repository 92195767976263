import React from 'react';
import * as yup from 'yup';
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {
    actionCreateCustomField,
    actionUpdateAttendanceVerificationOption
} from "../../../../../../../actions/admin/attendance-verification-options.actions";
import Card from "react-bootstrap/Card";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import {toast} from "react-toastify";
import {TextEditor} from "../../../../../../shared/text-editor";
import {toFormData} from "../../../../../../../utlis";


class AttendanceVerificationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 'fipicon-angle-left',
            formikState: this.getFormikState(),
            showForm: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            const stateObj = this.getFormikState();
            this.setState({formikState: stateObj});
        }
    }

    getValidationSchema = () => {
        return yup.object({
            name: yup.string().required(),
            shortName: yup.string().required(),
            type: yup.string().required()
        });
    };

    handleSubmit = async (values, action) => {
        const {actionCreateCustomField, data, actionUpdateAttendanceVerificationOption} = this.props;
        try {
            if (data?._id) {
                const obj = {
                    ...values,
                    _id: data._id
                };
                const formData = toFormData(obj);
                await actionUpdateAttendanceVerificationOption(obj._id, formData);
            } else {
                const formData = toFormData(values);
                await actionCreateCustomField(formData);
                action.resetForm();
            }
            this.props.handleClose();
            toast.success("Record is saved");
        } catch (e) {

        }
    };

    getFormikState = () => {
        const {data} = this.props;
        return {
            name: data?.name || '',
            shortName: data?.shortName || '',
            type: data?.type || 'text',
            icon: data?.icon || '',
            image: data?.image || '',
            iconType: data?.iconType || 'predefined',
            description: data?.description || '',
            prefix: data?.prefix || '',
            suffix: data?.suffix || '',
            isActive: data?.isActive
        }
    };

    handleChange = (value) => {
        this.setState({value});
    };

    render() {
        const {formikState} = this.state;
        const {data} = this.props;
        const props = {
            icons: ['fab fa-500px'],
            theme: 'bluegrey',
            renderUsing: 'class',
            isMulti: false,
        };
        return (

            <Formik
                validationSchema={this.getValidationSchema()}
                onSubmit={this.handleSubmit}
                enableReinitialize
                initialValues={{...formikState}}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      setFieldValue
                  }) => (
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Attendance Record Verification Option
                            </Card.Title>
                            <span style={{position: "absolute", top: 10, right: 10}}>
                                   <i className="fas fa-times cursor-pointer"
                                      onClick={() => this.props.handleClose()}/>
                               </span>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Select Image</Form.Label>
                                        <br/>
                                        <label className="mr-2">
                                            <input type="radio" name="iconType" value="predefined"
                                                   checked={values.iconType === 'predefined'}
                                                   onChange={handleChange}/> Predefined
                                        </label>
                                        <br/>
                                        <label>
                                            <input type="radio" name="iconType" value="custom"
                                                   checked={values.iconType === 'custom'}
                                                   onChange={handleChange}/> Custom Image
                                        </label>
                                    </Form.Group>
                                    {values.iconType === 'predefined' &&
                                    <Form.Group as={Col} md="9">
                                        <Form.Label className="d-block">Icon</Form.Label>
                                        <FontIconPicker {...props} value={values.icon}
                                                        onChange={(value) => setFieldValue('icon', value)}/>
                                    </Form.Group>}
                                    {values.iconType === 'custom' &&
                                    <Form.Group as={Col} md="9">
                                        <Form.Label className="d-block">Custom Image</Form.Label>
                                        <input type="file"
                                               onChange={event => setFieldValue("image", event.currentTarget.files[0])}/>
                                    </Form.Group>}

                                    <Form.Group as={Col} md="4" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="shortName">
                                        <Form.Label>Short name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="shortName"
                                            value={values.shortName}
                                            onChange={handleChange}
                                            isInvalid={!!errors.shortName}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.shortName}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control
                                            onChange={handleChange}
                                            as="select"
                                            name="type"
                                            isInvalid={!!errors.type}>
                                            <option value="text">Text</option>
                                            <option value="boolean">Boolean</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Description</Form.Label>
                                        <TextEditor
                                            value={values.description}
                                            onBlur={newContent => setFieldValue('description', newContent)}/>
                                        <small className="text-danger">{errors.description}</small>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Prefix</Form.Label>
                                        <Form.Control
                                            type={"text"}
                                            value={values.prefix}
                                            name="prefix"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Suffix</Form.Label>
                                        <Form.Control
                                            type={"text"}
                                            value={values.suffix}
                                            name="suffix"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                {data && (

                                    <div className="d-flex align-content-center">
                                        <span className="mr-2">Status</span>
                                        <Toggle
                                            id={data._id}
                                            defaultChecked={values.isActive}
                                            onChange={(e) => setFieldValue('isActive', e.target.checked)}/>
                                    </div>
                                )}
                                <br/>
                                <Button variant="secondary" size="sm" className="mr-2"
                                        onClick={this.props.handleClose}>Close</Button>
                                <Button size="sm" type="submit">{data ? 'Save' : 'Add'}  </Button>
                            </Form>

                        </Card.Body>
                    </Card>

                )}
            </Formik>

        );
    }
}

export default connect(null, ({
    actionCreateCustomField,
    actionUpdateAttendanceVerificationOption
}))(AttendanceVerificationForm);
