import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import {convertDateTimeToCustomFormat, convertDateToCustomFormat, calculateTimeDiff} from "../../../../../utlis";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import {CustomDatePicker} from "../../../../shared/custom-date-picker";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import Badge from "react-bootstrap/Badge";
// import {
//     acknowledgeAttendance,
//     acknowledgeCompleteAttendance,
//     approveAttendanceRecord,
//     setSelectedAttendance
// } from "../../../../../../actions/user/attendance.actions";

import {AttendanceImage} from "../attendance-acknowledgement/attendance-image/index.js";
import { acknowledgeAttendance } from '../../../../../actions/user/attendance.actions';
import { ModalImage } from '../../../../shared/modal-image';
import {API_URL} from "../../../../../config";

export const AttendanceVerification = (props) => {
    const {show, handleClose, attendanceId,attendanceHistory} = props;
    const {selectedOrganization} = useSelector(state => state.organizationReducer);
    // const {attendanceHistory} = useSelector(state => state.user.attendanceReducer);
    const {ARVO_Fields} = useSelector(state => state.user.ARVO_Reducer);
    
    const [showModalImage, setShowModalImage] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [modalImageCaption, setModalImageCaption] = useState('');

    const [canAcknowledge, setCanAcknowledge] = useState(false);
    const [attendance, setAttendance] = useState({});
    const [user, setUser] = useState({});
    const [punchInDateTime, setPunchInDateTime] = useState(new Date());
    const [punchOutDateTime, setPunchOutDateTime] = useState(new Date());
    const [comment, setComment] = useState('');
    const [flags, setFlags] = useState([]);
    const [commentsErrorMsg, setCommentsErrorMsg] = useState('');

    useEffect(() => {
        if (attendanceHistory?.length > 0) {
            const found = attendanceHistory.find(at => at._id === attendanceId);
            if (found) {
                setAttendance(found);
                setUser(found.user);
                if (found.isVerified) {
                    setPunchInDateTime(new Date(found.verification.punchIn));
                    setPunchOutDateTime(new Date(found.verification.punchOut));
                    setComment(found.verification.comment);
                    setFlags(found.verification.flag?.map(flag => ({field: flag.field, value: flag.value})));
                }
                const isSupervisor = selectedOrganization.otSupervisorOn.some(userId => userId === found.user._id);
                setCanAcknowledge(isSupervisor);

                if (!attendance.isVerified && ARVO_Fields && ARVO_Fields.length) {
                    setFlags(ARVO_Fields.map(field => ({field: field._id, value: ''})));
                }
            }
        }
    }, [attendance.isVerified, attendanceHistory, attendanceId, selectedOrganization.otSupervisorOn, ARVO_Fields]);


    const getPunchInDateTime = () => {
        if (attendance.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest.adjustedTime.punchInDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance.punchInDateTime);
        }
    };

    const getPunchOutDateTime = () => {
        if (attendance.ifLate) {
            return convertDateTimeToCustomFormat(attendance.changeRequest?.adjustedTime.punchOutDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance.punchOutDateTime);
        }
    };

    const handleARVO_Change = (id, value) => {
        const newArr = [].concat(flags);
        const index = newArr.findIndex(obj => obj.field === id);
        newArr[index].value = value;
        setFlags(newArr);
    };

    const handleSubmit = (status) => {
        if (!comment.length) {
            setCommentsErrorMsg("To reject AR, comments are required");
            return;
        }
        const data = {punchIn: punchInDateTime, punchOut: punchOutDateTime, comment, status, flag: flags};
        props.handleVerifyAttendance(attendanceId, data);
        setCommentsErrorMsg("");
        setComment("");
    };
    const onClickImage = (imgUrl, caption) => {
        setModalImageUrl(imgUrl);
        setModalImageCaption(caption);
        setShowModalImage(true);
    };
    const handleBreakImageAcknowledge = (breakId, statusFor, approved) => {
        const data = {id: breakId, [statusFor]: approved};
        acknowledgeAttendance(attendance._id, {breaks: data});
    };
    const handleAttendanceAcknowledge = (statusFor, approved) => {
        acknowledgeAttendance(attendance._id, {[statusFor]: approved});
    };
    return (
        
        <Modal show={show} size={"lg"} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Attendance Record Verification
                    <span className="ml-1">
                    {attendance.isVerified ? <i className="fas fa-check-circle text-success"/> :
                        <i className="fas fa-question-circle"/>}
                    </span>


                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <ModalImage show={showModalImage} imageUrl={modalImageUrl}
                            caption={modalImageCaption} handleClose={() => setShowModalImage(false)}/>
                <Row className="form-group">
                    <Col md={6}>
                        <label>Attendance Date</label>
                        <input className="form-control"
                               value={convertDateToCustomFormat(attendance.createdAt)} readOnly/>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Col md={6}>
                        <label>Employee Username</label>
                        <input className="form-control" value={`${user.userName}`} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Employee Name</label>
                        <input className="form-control" value={`${user.firstName} ${user.lastName}`} readOnly/>
                    </Col>
                </Row>

                <Row>
                </Row>
                <Row className="form-group">
                    <Col md={6}>
                        <label>Actual PunchIn Time</label>
                        <input className="form-control"
                               value={getPunchInDateTime()}
                               readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Actual PunchOut Time</label>
                        <input className="form-control" readOnly
                               value={attendance.punchOutDateTime ? getPunchOutDateTime() : '----'}/>
                    </Col>
                    
                    <Col md={6}>
                        <label>PunchIn Comment</label>
                        <input className="form-control"
                               value={attendance.punchInComment ? attendance.punchInComment  : '----'}
                               readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>PunchOut Comment</label>
                        <input className="form-control"
                               value={attendance.punchOutComment ? attendance.punchOutComment  : '----'}
                               readOnly/>
                    </Col>
                    <Col md={6}>
                            <AttendanceImage
                                imgUrl={attendance.punchInImage}
                                acknowledged={attendance.punchInAck}
                                handleImageClick={() => onClickImage(`${attendance.punchInImage}`, 'Punch-In Image')}
                                handleAttendanceAcknowledge={(approved) => handleAttendanceAcknowledge('punchInAck', approved)}
                                canAcknowledge={canAcknowledge}
                                imageFor="Punch-In"
                            />

                    </Col>
                    <Col md={6}>
                        <AttendanceImage
                            imgUrl={attendance.punchOutImage}
                            acknowledged={attendance.punchOutAck}
                            handleImageClick={() => onClickImage(`${API_URL}/${attendance.punchOutImage}`, 'Punch-Out Image')}
                            handleAttendanceAcknowledge={(approved) => handleAttendanceAcknowledge('punchOutAck', approved)}
                            canAcknowledge={canAcknowledge}
                            imageFor="Punch-Out"
                        />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={6}>
                        <label>Approved PunchIn Time</label>
                        <DatePicker
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            timeInputLabel="Time:"
                            showTimeInput
                            selected={punchInDateTime}
                            onChange={date => setPunchInDateTime(date)}
                            customInput={<CustomDatePicker/>}
                            withPortal
                            disabled={attendance.isVerified}
                        />
                    </Col>
                    <Col md={6}>
                        <label>Approved PunchOut Time</label>
                        <DatePicker
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            timeInputLabel="Time:"
                            showTimeInput
                            selected={punchOutDateTime}
                            onChange={date => setPunchOutDateTime(date)}
                            customInput={<CustomDatePicker/>}
                            popperPlacement="bottom-end"
                            withPortal
                            disabled={attendance.isVerified}
                        />
                    </Col>

                </Row>
                
                <div className="d-flex px-4 mt-5">
                    <span style={{width: "20px"}}>#</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>BreakTime</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>ResumeTime</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>TotalTime</span>
                </div>

                {attendance && attendance.breakTime?.map((breakTime, index) => (
                    <Accordion key={breakTime._id}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="cursor-pointer">
                                <div className="d-flex">
                                    <span style={{width: "20px"}}>{index + 1}</span>
                                    <span style={{width: "33%"}}>
                                        {convertDateTimeToCustomFormat(breakTime.startTime)}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? convertDateTimeToCustomFormat(breakTime.endTime) : '----'}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? calculateTimeDiff(breakTime.endTime, breakTime.startTime) : '----'}
                                    </span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row className="py-3">
                                        <Col md={{span: 6}}>
                                            <span>Break #{index + 1} image</span>
                                        </Col>
                                        <Col md={1}/>
                                        <Col md={4}>
                                            <span>Resume #{index + 1} image</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span: 6}}>
                                            <div className="position-relative">
                                                <input className="form-control"
                                                    value={breakTime.startComment}
                                                    readOnly/>
                                            </div>
                                            <AttendanceImage
                                                imgUrl={breakTime.startImage}
                                                acknowledged={breakTime.startAck}
                                                handleImageClick={() => onClickImage(`${breakTime.startImage}`, `Break #${index + 1} image`)}
                                                handleAttendanceAcknowledge={(approved) => handleBreakImageAcknowledge(breakTime._id, 'startAck', approved)}
                                                canAcknowledge={canAcknowledge}
                                                imageFor="Start Break"
                                            />

                                        </Col>
                                        <Col md={6}>

                                        <div className="position-relative">
                                                <input className="form-control"
                                                    value={breakTime.endComment}
                                                    readOnly/>
                                            </div>
                                            <AttendanceImage
                                                imgUrl={breakTime.endImage}
                                                acknowledged={breakTime.endAck}
                                                handleImageClick={() => onClickImage(`${breakTime.endImage}`, `Resume #${index + 1} image`)}
                                                handleAttendanceAcknowledge={(approved) => handleBreakImageAcknowledge(breakTime._id, 'endAck', approved)}
                                                canAcknowledge={canAcknowledge}
                                                imageFor="End Break"
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))}
                <Row>
                    
                    <Col>
                        <label>Comments</label>
                        <textarea
                            className="form-control"
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            readOnly={attendance.isVerified}
                        />
                        <small className="text-danger">{commentsErrorMsg}</small>
                    </Col>
                </Row>

                {ARVO_Fields?.map(field => (
                    <Accordion className="mt-1" key={field._id}>
                        <Card>
                            <Card.Header>
                                <div className="d-flex">
                                    <span className="flex-grow-1 align-self-center">{field.name}</span>
                                    <div className="mr-1 align-content-center d-flex">
                                        <span className="mr-1">{field.prefix}</span>
                                        {field.type === 'boolean' && (
                                            <Toggle
                                                icons={false}
                                                defaultChecked={false}
                                                onChange={(e) => handleARVO_Change(field._id, e.target.value)}/>
                                        )}
                                        {field.type === 'text' && (
                                            <input type="text"/>
                                        )}
                                        <span className="ml-1">{field.suffix}</span>
                                    </div>

                                    <Accordion.Toggle as={Button} variant="default" size="sm" eventKey={field._id}>
                                        <i className="fas fa-chevron-down"/>
                                    </Accordion.Toggle>

                                </div>

                            </Card.Header>
                            <Accordion.Collapse eventKey={field._id}>
                                <Card.Body>{field.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))}

                {attendance.isVerified && (
                    <div className="mt-2">
                        {attendance.verification?.status === 'approved' ? <Badge variant="success">Approved by</Badge> :
                            <Badge variant="danger">Rejected by</Badge>}
                        <span className="font-weight-bold ml-1">
                            {attendance.verification?.supervisor.userName}
                        </span>
                        <small className="d-block">
                            {convertDateTimeToCustomFormat(attendance.verification?.createdAt)}
                        </small>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    Close
                </Button>
                {!attendance.isVerified &&
                <>
                    <Button variant="danger" size="sm" onClick={() => handleSubmit('rejected')}>
                        Reject
                    </Button>
                    <Button variant="success" size="sm" onClick={() => handleSubmit('approved')}>
                        Approve
                    </Button>
                </>
                }

            </Modal.Footer>
        </Modal>
    );
};
