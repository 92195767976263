import en from './en';
import zHsG from './zh-sg';

export default {
    en: {
        lang: en,
        emoji: '🇺🇸'
    },
    'zh-sg': {
        lang: zHsG,
        emoji: '🇨🇳'
    }
};
