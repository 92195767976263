import {adminConstants} from "../../constants";

const initialState = {
    announcements: [],
    announcement: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.CREATE_ANNOUNCEMENT:
            return {
                ...state,
                announcements: [action.payload, ...state.announcements]
            };

        case adminConstants.GET_ANNOUNCEMENTS:
            return {
                ...state,
                announcements: action.payload
            };
        case adminConstants.GET_ONE_ANNOUNCEMENT:
            return {
                ...state,
                announcement: action.payload
            };

        default:
            return state;
    }
}
