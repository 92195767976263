import {adminConstants} from "../../constants";
import axios from '../axiosConfig';
import {toast} from 'react-toastify';
import {trackPromise} from 'react-promise-tracker';

export const getGroupsAll = () => async dispatch => {
    const response = await trackPromise(axios.get(`groups/list/all`));
    dispatch({
        type: adminConstants.GET_GROUPS,
        payload: response.data.data
    });
};

export const getGroups = () => async dispatch => {
    const response = await trackPromise(axios.get(`groups/list`));
    dispatch({
        type: adminConstants.GET_GROUPS,
        payload: response.data.data
    });
};

export const getGroup = (groupId) => async dispatch => {
    const response = await trackPromise(axios.get(`groups/list/${groupId}`));
    dispatch({
        type: adminConstants.GET_GROUP,
        payload: response.data.data
    });
};

export const addGroup = (group) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`groups/new`, group));
        toast.success('New group is created.');
        dispatch({
            type: adminConstants.ADD_GROUP,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while saving group');
        throw error;
    }
};

export const updateGroup = (group) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`groups/edit/${group._id}`, group));
        toast.success('Group is updated.');

        dispatch({
            type: adminConstants.UPDATE_GROUP,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while saving group');
    }
};

export const deleteGroup = (groupId) => async dispatch => {
    try {
        const response = await trackPromise(axios.delete(`groups/delete/${groupId}`));
        toast.success('The group is deleted.');
        dispatch({
            type: adminConstants.DELETE_GROUP,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while delete group');
        throw error;
    }
};

export const restoreGroup = (groupId) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`groups/restore/${groupId}`));
        toast.success('Group is restored.');

        dispatch({
            type: adminConstants.UPDATE_GROUP,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while restore group');
    }
};
