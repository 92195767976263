import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import './SideBar.css';
import SideNavItem from './side-nav-item/SideNavItem';
import { useSelector } from 'react-redux';

import { getVersion } from "../../../utlis";

function SideBar(props) {
  const location = useLocation();
  const { lang } = useSelector((state) => state.langReducer);
  const { selectedOrganization } = useSelector(
    (state) => state.organizationReducer
  );
  const urlAdmin = "/admin";
  const urlUser = "/user";

  const [adminNavigation, setAdminNavigation] = useState([]);

  useEffect(() => {
    let adminNavs = [
      {
        displayName: lang.Dashboard,
        route: `${urlAdmin}/dashboard`,
        icon: <i className="fas fa-tachometer-alt" />,
        children: [],
      },
      {
        displayName: lang.GroupManagement,
        icon: <i className="far fa-object-group" />,
        children: [
          {
            displayName: lang.AllGroups,
            route: `${urlAdmin}/groups`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: lang.AddGroup,
            route: `${urlAdmin}/groups/add`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
        ],
      },
      {
        displayName: lang.OutletManagement,
        icon: <i className="fas fa-location-arrow" />,
        children: [
          {
            displayName: lang.AllOutlets,
            route: `${urlAdmin}/departments`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: lang.AddOutlet,
            route: `${urlAdmin}/departments/add`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
        ],
      },
      {
        displayName: lang.EmployeesManagement,
        icon: <i className="fas fa-users" />,
        children: [
          {
            displayName: lang.AllEmployees,
            route: `${urlAdmin}/employees`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: lang.AddEmployee,
            route: `${urlAdmin}/employees/add`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
        ],
      },
      {
        displayName: lang.Announcements,
        icon: <i className="far fa-bell" />,
        children: [
          {
            displayName: lang.AllAnnouncements,
            route: `${urlAdmin}/announcements`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: 'Add Announcement',
            route: `${urlAdmin}/announcements/add`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
        ],
      },
      {
        key: 'attendance-management',
        displayName: lang.AttendanceManagement,
        icon: <i className="fas fa-hourglass-half" />,
        children: [
          {
            displayName: lang.All,
            route: `${urlAdmin}/attendances/all`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: lang.Flagged,
            route: `${urlAdmin}/attendance/flagged`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          {
            displayName: lang.Pending,
            route: `${urlAdmin}/attendance/pending`,
            icon: <i className="far fa-circle" />,
            children: [],
          },
          // {
          //     displayName: lang.PendingApprovals,
          //     route: `${urlAdmin}/attendances/pending`,
          //     icon: <i className="far fa-circle"/>,
          //     children: []
          // },
          // {
          //     displayName: lang.Approved,
          //     route: `${urlAdmin}/attendances/approved`,
          //     icon: <i className="far fa-circle"/>,
          //     children: []
          // },
          // {
          //     key: "pending-ack-approval",
          //     displayName: lang.PendingAckApproval,
          //     route: `${urlAdmin}/attendance/pending-pending-acknowledgements`,
          //     icon: <i className="far fa-circle"/>,
          //     children: []
          // },

          // {
          //   displayName: lang.Settings,
          //   icon: <i className="fas fa-cogs" />,
          //   route: `${urlAdmin}/attendance/settings`,
          //   children: [],
          // },
        ],
      },
      // {
      //   displayName: lang.ContentManagement,
      //   icon: <i className="fas fa-stream" />,
      //   children: [
      //     {
      //       displayName: lang.AllContents,
      //       route: `${urlAdmin}/contents`,
      //       icon: <i className="far fa-circle" />,
      //       children: [],
      //     },
      //     {
      //       displayName: lang.AddContent,
      //       route: `${urlAdmin}/contents/add`,
      //       icon: <i className="far fa-circle" />,
      //       children: [],
      //     },
      //   ],
      // },
      {
        displayName: lang.ActivityLogs,
        route: `${urlAdmin}/activity-logs`,
        icon: <i className="fas fa-list-ul" />,
        children: [],
      },
      {
        displayName: lang.OrgSettings,
        icon: <i className="fas fa-cogs" />,
        route: `${urlAdmin}/settings`,
        children: [],
      },
    ];

    adminNavs = adminNavs.map((nav) => {
      if (nav.key === 'attendance-management') {
        if (!selectedOrganization?.verifyEachAttendance) {
          nav.children = nav.children.filter(
            (child) => child.key !== 'pending-ack-approval'
          );
          return nav;
        }
      }
      return nav;
    });
    setAdminNavigation(adminNavs);
  }, [selectedOrganization, lang, urlAdmin]);

  const userNavigation = [
    {
      displayName: lang.Dashboard,
      route: `${urlUser}/dashboard`,
      icon: <i className="fas fa-tachometer-alt" />,
      children: [],
    },
    {
      displayName: lang.Attendance,
      icon: <i className="fas fa-hourglass-half" />,
      children: [
        {
          displayName: 'Me',
          route: `${urlUser}/attendance/own`,
          icon: <i className="far fa-circle" />,
          children: [],
        },
        {
          displayName: 'My subs',
          route: `${urlUser}/attendance/mysub`,
          icon: <i className="far fa-circle" />,
          children: [],
        },
        {
          displayName: 'My department',
          route: `${urlUser}/attendance/mydepartment`,
          icon: <i className="far fa-circle" />,
          children: [],
        },
        {
          displayName: 'My group',
          route: `${urlUser}/attendance/mygroup`,
          icon: <i className="far fa-circle" />,
          children: [],
        },
      ],
    },
    {
      displayName: lang.Announcements,
      route: `${urlUser}/announcements`,
      icon: <i className="far fa-bell" />,
      children: [],
    },
    {
      displayName: lang.ActivityLogs,
      route: `${urlUser}/activity-logs`,
      icon: <i className="fas fa-list-ul" />,
      children: [],
    },
  ];

  return (
    <div className="sidebar-bg border-right" id="sidebar-wrapper">
      <div className="sidebar-heading">
        <h4 className="mb-0">Attendance</h4>
        <small className="version">
          Version {getVersion()}
        </small>
        {location.pathname.includes('admin') && <h6 className="mt-1">Admin</h6>}
      </div>
      <ul className="list-unstyled nav-ul">
        {location.pathname.includes('admin') &&
          adminNavigation.map((nav, index) => (
            <SideNavItem navItem={nav} key={`${nav.displayName}${index}`} />
          ))}

        {location.pathname.includes('user') &&
          userNavigation.map((nav, index) => (
            <SideNavItem navItem={nav} key={`${nav.displayName}${index}`} />
          ))}
      </ul>
    </div>
  );
}

export default SideBar;
