import {adminConstants} from "../../constants";

const initialState = {
    contents: [],
    content: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.CREATE_CONTENT:
            return {
                ...state,
                contents: [action.payload, ...state.announcements]
            };

        case adminConstants.GET_CONTENTS:
            return {
                ...state,
                contents: action.payload
            };
        case adminConstants.GET_ONE_CONTENT:
            return {
                ...state,
                content: action.payload
            };

        default:
            return state;
    }
}
