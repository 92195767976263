import React from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from '../../../../actions/axiosConfig';
import {toast} from "react-toastify";

export class AccountConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: '',
            validated: false,
            password: ''
        }
    }

    componentDidMount() {
        const {search} = this.props.location;
        const queryParams = new URLSearchParams(search);
        if (queryParams.get('token')) {
            const token = queryParams.get('token');
            this.setState({token});
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        this.setState({validated: true});
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return false;
        }

        const {token, password} = this.state;

        axios.put('/auth/setPassword', {token, password}).then(response => {
            toast.success('Your account is activated');
            this.props.navigate('/login');
        });
    };

    render() {
        const {validated} = this.state;
        return (
            <Container className="mt-5">
                <Card>
                    <Card.Body>
                        <Card.Title>Activate your account</Card.Title>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="validationCustom01">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="Enter your password"
                                        onChange={this.handleChange}
                                        name="password"
                                        minLength={8}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Button type="submit">Activate your account</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}
