import axios from "../axiosConfig";
import {userConstants} from "../../constants";

export const actionGetOutlets = () => async dispatch => {

    return axios.get('employees/outlets').then(response => {
        dispatch({type: userConstants.ACTION_GET_USER_OUTLETS, payload: response.data.data});
        return response;
    });

};
