import React, {Component} from "react";
import './Dashboard.css';
import Container from "react-bootstrap/Container";
import {getDashboardCount} from '../../../../actions/admin/dashboard.action';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

class Dashboard extends Component {
    componentDidMount() {
        this.props.getDashboardCount();
    }

    render() {
        const {lang, dashboardCounts} = this.props;
        const {} = this.props ;
        return (
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="dbox dbox--color-1">
                            <div className="dbox__icon">
                                <i className="fas fa-users"/>
                            </div>
                            <div className="dbox__body">
                                <span className="dbox__count">{dashboardCounts.Users}</span>
                                <span className="dbox__title">{lang.TotalEmployees}</span>
                            </div>

                            <div className="dbox__action">
                                <button className="dbox__action__btn"
                                        onClick={() => this.props.navigate('/admin/employees')}>
                                    {lang.MoreInfo}</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dbox dbox--color-2">
                            <div className="dbox__icon">
                                <i className="far fa-bell"/>
                            </div>
                            <div className="dbox__body">
                                <span className="dbox__count">{dashboardCounts.Contents}</span>
                                <span className="dbox__title">{lang.TotalAnnouncements}</span>
                            </div>

                            <div className="dbox__action">
                                <button className="dbox__action__btn"
                                        onClick={() => this.props.navigate('/admin/announcements')}>
                                    {lang.MoreInfo}</button>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="dbox dbox--color-3">
                            <div className="dbox__icon">
                                <i className="far fa-object-group"/>
                            </div>
                            <div className="dbox__body">
                                <span className="dbox__count">{dashboardCounts.Groups}</span>
                                <span className="dbox__title">{lang.TotalGroups}</span>
                            </div>
                            <div className="dbox__action">
                                <button className="dbox__action__btn"
                                        onClick={() => this.props.navigate('/admin/groups')}>
                                    {lang.MoreInfo}</button>
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="dbox dbox--color-1">
                            <div className="dbox__icon">
                                <i className="far fa-object-group"/>
                            </div>
                            <div className="dbox__body">
                                <span className="dbox__count">{dashboardCounts.Departments}</span>
                                <span className="dbox__title">{lang.TotalOutlets}</span>
                            </div>
                            <div className="dbox__action">
                                <button className="dbox__action__btn"
                                        onClick={() => this.props.navigate('/admin/departments')}>
                                    {lang.MoreInfo}</button>
                            </div>

                        </div>
                    </Col>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <div className="dbox dbox--color-3">
                            <div className="dbox__icon">
                                <i className="far fa-object-group"/>
                            </div>
                            <div className="dbox__body">
                                <span className="dbox__count">{dashboardCounts.Pendings}</span>
                                <span className="dbox__title">{lang.PendingApprovals}</span>
                            </div>
                            <div className="dbox__action">
                                <button className="dbox__action__btn"
                                        onClick={() => this.props.navigate('/admin/attendance/pending')}>
                                    {lang.MoreInfo}</button>
                            </div>

                        </div>
                    </Col>


                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({admin: {dashboardReducer}, langReducer}) => ({
    lang: langReducer.lang,
    dashboardCounts: dashboardReducer.dashboardCounts
});
export default connect(mapStateToProps, ({getDashboardCount}))(Dashboard);
