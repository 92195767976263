import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {convertDateTimeToCustomFormat} from "../../../../../../../utlis";
import {API_URL} from "../../../../../../../config";

export const ViewAcknowledgeModal = (props) => {
    const {showModal, handleCloseModal, data, lang} = props;
    return (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{lang.ViewAcknowledgement}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Container fluid>
                    <Row>
                        <Col md={4} className="font-weight-bold">{lang.Acknowledgement}</Col>
                        <Col md={8}>{data.acknowledgement}</Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4} className="font-weight-bold">{lang.AcknowledgedOn}</Col>
                        <Col md={8}>{convertDateTimeToCustomFormat(data.DateTime)}</Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4} className="font-weight-bold">{lang.Image}</Col>
                        <Col md={8}>
                            <img src={`${data.image}`} width="100%" height="150" alt="Acknowledgement"/>
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleCloseModal}>{lang.Close}</Button>
            </Modal.Footer>
        </Modal>
    )
};
