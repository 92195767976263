import React from "react";
import {connect} from "react-redux";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {addOutlet, getOutlet, updateOutlet} from "../../../../actions/admin/outlet.actions";


class OutletDefinition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            action: '',
            form: {
                name: '',
                description: '',
                remarks: '',
                status: 'A',
                validated: false
            }
        };
    }


    componentDidMount() {
        const { params } = this.props;
        this.setState({action: params.action});
        if (params.action === 'edit') {
            const {search} = this.props.location;
            const queryParams = new URLSearchParams(search);
            if (queryParams.get('outletId')) {
                const outletId = queryParams.get('outletId');
                this.props.getOutlet(outletId).then(() => {
                    this.setFormStateForEdit();
                });
            }
        }
    }

    createNewOutlet = () => {
        this.props.addOutlet(this.state.form).then(() => {
            this.resetFormState();
            this.props.navigate('/admin/departments');
        }).catch(() => {

        });
    };

    handleChange = (e) => {
        const {form} = this.state;
        form[e.target.name] = e.target.value;
        this.setState(form)
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const htmlForm = event.currentTarget;
        if (htmlForm.checkValidity() === false) {
            event.stopPropagation();
        }
        const {form} = this.state;
        form.validated = true;
        this.setState({form});

        this.state.action === 'add' ? this.createNewOutlet() : this.updateOutlet();
    };

    setFormStateForEdit = () => {
        const {form} = this.state;
        const {outlet} = this.props;

        form._id = outlet._id;
        form.name = outlet.name;
        form.comments = outlet.comment;
        form.location = outlet.location;
        form.status = outlet.status;

        this.setState({form});
    };

    resetFormState = () => {
        const {form} = this.state;
        form.name = '';
        form.comments = '';
        form.location = '';
        form.validated = false;
        this.setState({form});
    };

    updateOutlet = () => {
        this.props.updateOutlet(this.state.form).then(() => {
            this.props.navigate('/admin/departments');
        });
    };

    render() {
        const {action, form: {name, comments, location, status, validated}} = this.state;
        const {lang} = this.props;
        return (
            <Container fluid>
                <Card className="mt-4 shadow">
                    <Card.Body>
                        <Card.Title>{action === 'add' ? lang.AddOutlet : lang.EditOutlet}</Card.Title>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Form.Group controlId="groupName">
                                <Form.Label>{lang.Name}</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={name}
                                    name="name"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="groupDescription">
                                <Form.Label>{lang.Comments}</Form.Label>
                                <Form.Control
                                    as="textarea" rows="5"
                                    required
                                    type="text"
                                    value={comments}
                                    name="comment"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="groupRemarks">
                                <Form.Label>{lang.Location}</Form.Label>
                                <Form.Control
                                    as="textarea" rows="3"
                                    type="text"
                                    value={location}
                                    name="location"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="groupStatus">
                                <Form.Label>{lang.Status}</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="text"
                                    value={status}
                                    name="status"
                                    onChange={this.handleChange}>
                                    <option value="A">{lang.Active}</option>
                                    <option value="I">{lang.Inactive}</option>
                                </Form.Control>
                            </Form.Group>
                            <Button type="submit" variant="primary">{action === 'add' ? lang.Add : lang.Save}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = ({admin: {outletReducer}, langReducer}) => ({
    outlet: outletReducer.outlet,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({addOutlet, getOutlet, updateOutlet}))(OutletDefinition);
