import axios from "../axiosConfig";
import {
    adminConstants
} from "../../constants";
import {
    trackPromise
} from "react-promise-tracker";
import moment from 'moment';

export const actionGetAttendanceList = (params) => async dispatch => {

    const timezoneOffset = (new Date()).getTimezoneOffset();
    const response = await axios.get('/admin/attendance/list', {
        params: {
            ...params,
            timezoneOffset
        }
    });
    const processedData = response.data.data;
    // let data = response.data.data;
    // data.map((d) => {
    //     let targetPunchInTime = new Date(d.punchInDateTime);
    //     let timeZoneFromDB = d.org.timeZoneOffset; 
    //     let punchIntzDifference = timeZoneFromDB * 60 + targetPunchInTime.getTimezoneOffset();
    //     let punchInOffsetTime = new Date(targetPunchInTime.getTime() + punchIntzDifference * 60 * 1000);

    //     let targetPunchOutTime = new Date(d.punchOutDateTime);
    //     let punchOuttzDifference = timeZoneFromDB * 60 + targetPunchOutTime.getTimezoneOffset();
    //     let punchOutOffsetTime = new Date(targetPunchOutTime.getTime() + punchOuttzDifference * 60 * 1000);
    //     d.punchInDateTime = punchInOffsetTime;
    //     d.punchOutDateTime = punchOutOffsetTime == 'Invalid Date' ? new Date() : punchOutOffsetTime;
    //     processedData.push(d);
    // });

    dispatch({
        type: adminConstants.GET_ATTENDANCE_LIST,
        payload: processedData
    });
    return response;
};
export const actionGetAttendance = (id) => async dispatch => {


    const response = await axios.get(`/attendance/getone/${id}`);

    dispatch({
        type: adminConstants.GET_ATTENDANCE_ONE,
        payload: response.data.data
    });

    return response;
};
export const actionUpdateAttendance = (attendance) => async dispatch => {
    const response = await axios.put(`/attendance/editattendance/${attendance._id}`, attendance);
    dispatch({
        type: adminConstants.UPDATE_ATTENDANCE_ONE,
        payload: response.data.data
    });
    return response;
};


export const actionGetPendingAcknowledgeAttendanceList = () => async dispatch => {
    const response = await axios.get('/admin/attendance/pending-ack');
    dispatch({
        type: adminConstants.GET_PENDING_ACK_ATTENDANCES,
        payload: response.data.data
    });
};

export const actionGetAckAttendanceList = (status) => async dispatch => {
    const response = await axios.get('/admin/attendance/ack-list/?status=' + status);
    dispatch({
        type: adminConstants.GET_PENDING_ACK_ATTENDANCES,
        payload: response.data.data
    });
};


export const acknowledgeAttendance = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/attendance/ack/${attendanceId}`, data));
        dispatch({
            type: adminConstants.ACTION_ADMIN_ACK_EMPLOYEE_ATTENDANCE,
            payload: response.data.data
        });
        return response;
    } catch (e) {

    }
};

export const acknowledgeCompleteAttendance = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`employees/attendance/bulkAck/${attendanceId}`, data));
        dispatch({
            type: adminConstants.ACTION_ADMIN_ACK_EMPLOYEE_ATTENDANCE,
            payload: response.data.data
        });
        return response;
    } catch (e) {

    }
};

export const approveAttendanceRecord = (attendanceId, data) => async dispatch => {
    try {
        const response = await trackPromise(axios.put(`attendance/verify/${attendanceId}`, data));
        dispatch({
            type: adminConstants.ACTION_APPROVE_ATTENDANCE_RECORD,
            payload: response.data.data
        });
        return response;
    } catch (e) {

    }
};

export const setSelectedAttendance = (attendance) => dispatch => {
    dispatch({
        type: adminConstants.SET_SELECTED_ATTENDANCE,
        payload: attendance
    })
};