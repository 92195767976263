import {authenticationConstants} from '../constants';

let loggedInUser = JSON.parse(localStorage.getItem('user'));
const initialState = loggedInUser ? {loggedIn: true, loggedInUser} : {};

const INITIAL_STATE = {
    ...initialState
};

export function authentication(state = INITIAL_STATE, action) {
    switch (action.type) {
        case authenticationConstants.LOGGED_IN:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return {
                loggedIn: true,
                loggedInUser: action.payload
            };
        case authenticationConstants.LOG_OUT:
            localStorage.clear();
            return {};
        default:
            return state
    }
}
