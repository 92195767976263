import React from 'react';
import {connect} from "react-redux";
import {actionGetOrganizationsByLogin, setSelectedOrganization} from "../../../../actions/organization.actions";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

class SelectOrganization extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOrganization: null
        }
    }

    componentDidMount() {
        this.props.actionGetOrganizationsByLogin();
    }

    handleChangeSelectOrg = (event) => {
        this.setState({selectedOrganization: event.target.value});
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.organizations !== this.props.organizations) {
            if (this.props.organizations.length === 1) {
                this.setState({selectedOrganization: this.props.organizations[0].corpUid}, () => {
                    this.navigateToRedirectRoute();
                });
            }
        }
    }

    navigateToRedirectRoute = () => {
        const organization = this.props.organizations.find(org => org.corpUid === this.state.selectedOrganization);
        this.props.setSelectedOrganization(this.state.selectedOrganization);
        const {isAdmin, isSuperAdmin} = organization;
        isSuperAdmin || isAdmin ? this.props.navigate('/admin/dashboard') : this.props.navigate('/user/dashboard');
        // const {redirectUrl} = this.props.location.state;
        // this.props.navigate(redirectUrl);
    };

    render() {
        return (
            <Container>
                <Row>
                    <Col md={{span: 4, offset: 4}}>
                        <Card className="mt-5">
                            <Card.Body>
                                <label>Choose Organization</label>
                                <select className="form-control"
                                        onChange={this.handleChangeSelectOrg}>
                                    <option value="">Choose</option>
                                    {this.props.organizations.map(org => (
                                        <option value={org.corpUid}>{org.corpName}</option>
                                    ))}
                                </select>
                                <Button variant={"success"} size="sm" className="mt-2"
                                        onClick={this.navigateToRedirectRoute}>Continue</Button>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({organizationReducer}) => ({
    organizations: organizationReducer.organizations
});
export default connect(mapStateToProps, ({actionGetOrganizationsByLogin, setSelectedOrganization}))(SelectOrganization);
