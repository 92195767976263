import {adminConstants} from "../../constants";

const initialState = {
    groups: [],
    group: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_GROUPS:
            return {
                ...state,
                groups: action.payload
            };
        case adminConstants.GET_GROUP:
            return {
                ...state,
                group: action.payload
            };

        case adminConstants.UPDATE_GROUP:
            return {
                ...state,
                groups: state.groups.map(group => group._id === action.payload._id ? (group = action.payload) : group)
            };
        case adminConstants.ADD_GROUP:
            return {
                ...state,
                groups: [action.payload, ...state.groups]
            };

        case adminConstants.DELETE_GROUP:
            return {
                ...state,
                groups: state.groups.filter(group => group._id !== action.payload)
            };

        default:
            return state;
    }
}
