import React from 'react';
import Select from "react-select";

const countryJson = require('countrycitystatejson');


export const SelectCountry = (props) => {

    const {onChange, selected} = props;

    const countries = countryJson.getCountries().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return (
        <Select
            options={countries.map(country => ({
                value: country.shortName,
                label: country.emoji + '  ' + country.name
            }))}
            value={countries.filter(country => country.shortName === selected)
                .map(country => ({
                    value: country.shortName,
                    label: country.emoji + '  ' + country.name
                }))}
            onChange={selected => onChange(selected.value)}
            isSearchable
        />
    )
};
