import React from "react";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../utlis";
import {userConstants} from "../../../../../constants";
import Badge from "react-bootstrap/Badge";
import DatePicker from "react-datepicker";
import {CustomDatePicker} from "../../../../shared/custom-date-picker";
import DataTable from "react-data-table-component";
import {connect} from "react-redux";
import {
    actionGetAttendanceList,
    approveAttendanceRecord,
    setSelectedAttendance
} from "../../../../../actions/admin/attendance.actions";
import {AttendanceVerification} from "../../../shared/attendance/attendance-verification";
import axios from '../../../../../actions/axiosConfig';

class AttendanceVerificationRecords extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterBy: 'today',
            dateFrom: new Date(),
            dateTill: new Date(),
            approvalStatus: '',
            showApprovalModal: false,
            selectedAttendance: null
        }

    }

    componentDidMount() {
        const {status} = this.props;
        this.setState({approvalStatus: status});
        const {filterBy} = this.state;
        this.props.actionGetAttendanceList({filterBy, status});
    }

    exportCSV = () => {
        const {filterBy, dateFrom, dateTill, approvalStatus} = this.state;
        const params = {
            filterBy,
            dateFrom,
            dateTill,
            status: approvalStatus,
            timeZoneOffSet: (new Date()).getTimezoneOffset()
        };
        axios.get("/attendance/export/file", {params, responseType: 'arraybuffer'}).then(response => {
            var blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'attendance.xlsx');

            document.body.appendChild(link);
            link.click();
        });
    };

    handleFilterChange = (e) => {
        const filterBy = e.target.value;
        this.setState({filterBy});
    };

    onClickSearchByDate = () => {
        const {filterBy, dateFrom, dateTill, approvalStatus} = this.state;
        this.props.actionGetAttendanceList({filterBy, dateFrom, dateTill, status: approvalStatus});
    };

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                sortable: true,
                selector: 'user.firstName',
                cell: row => row.user.firstName + ' ' + row.user.lastName
            },
            {
                name: lang.Outlet,
                selector: 'outlet.name',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                sortable: true,
                selector: 'punchInDateTime',
                cell: row => convertDateTimeToCustomFormat(row.punchInDateTime)
            },
            {
                name: lang.PunchOutTime,
                sortable: true,
                selector: 'punchOutDateTime',
                cell: row => (
                    row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    const endTime = row.punchOutDateTime ? row.punchOutDateTime : (new Date()).toISOString();
                    return calculateTimeDiff(endTime, row.punchInDateTime)
                }
            },
            // {
            //     name: lang.Actions
            // }

        ]
    };

    renderFilterControls = () => {
        const {lang} = this.props;
        const {filterBy, dateFrom, dateTill} = this.state;
        return (
            <div className="row w-100">
                <div className="col-md-3 form-group">
                    <label>{lang.FilterBy}</label>
                    <select className="form-control" value={filterBy} onChange={this.handleFilterChange}>
                        <option value="today">{lang.Today}</option>
                        <option value="week">{lang.LastSevenDays}</option>
                        <option value="month">{lang.LastThirtyDays}</option>
                        <option value="threeMonth">{lang.LastNinetyDays}</option>
                        <option value="year">{lang.LastTwelveMonths}</option>
                        <option value="DR">{lang.DateRange}</option>
                    </select>
                </div>
                {filterBy === 'DR' && (
                    <>
                        <div className="col-md-3">
                            <label>{lang.DateFrom}</label>
                            <DatePicker
                                dateFormat="dd-MMM-yyyy"
                                selected={dateFrom}
                                onChange={date => this.setState({dateFrom: date})}
                                customInput={<CustomDatePicker/>}
                            />
                        </div>
                        <div className="col-md-3">
                            <label>{lang.DateTo}</label>
                            <DatePicker
                                dateFormat="dd-MMM-yyyy"
                                selected={dateTill}
                                onChange={date => this.setState({dateTill: date})}
                                customInput={<CustomDatePicker/>}
                            />
                        </div>
                    </>
                )}

                <div className="col-md-3 pt-4">
                    <button className="btn btn-primary mt-1 mr-1"
                            onClick={this.onClickSearchByDate}>{lang.Search}
                    </button>
                    <button className="btn btn-success mt-1" onClick={this.exportCSV}>Export to CSV</button>
                </div>

            </div>
        )
    };
    handleClosing = () => {
        this.setState({showApprovalModal: false})
        const {status} = this.props;
        this.setState({approvalStatus: status});
        const {filterBy} = this.state;
        this.props.actionGetAttendanceList({filterBy, status});
    }
    render() {
        const {showApprovalModal, selectedAttendance} = this.state;
        const {lang, attendanceList, title, approveAttendanceRecord} = this.props;
        
        return (
            <div className="rdt_approval_record">
                <DataTable
                    title={title}
                    columns={this.renderColumns()}
                    data={attendanceList}
                    pagination={true}
                    subHeader
                    subHeaderComponent={this.renderFilterControls()}
                    subHeaderAlign="left"
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={(row) => {
                        this.setState({showApprovalModal: true, selectedAttendance: row})
                    }}
                />
                <AttendanceVerification
                    attendanceHistory={attendanceList}
                    attendanceId={selectedAttendance?._id}
                    handleVerifyAttendance={approveAttendanceRecord}
                    show={showApprovalModal}
                    handleClose={() => this.handleClosing()}/>
            </div>
        );
    }
}

const mapStateToProps = ({langReducer, admin: {attendanceReducer}}) => ({
    lang: langReducer.lang,
    attendanceList: attendanceReducer.V_AttendanceRecords,
    selectedAttendance: attendanceReducer.selectedAttendance
});

export default connect(mapStateToProps, ({
    actionGetAttendanceList,
    setSelectedAttendance,
    approveAttendanceRecord
}))(AttendanceVerificationRecords);
