import React from 'react';
import {connect} from "react-redux";
import {convertDateToCustomFormat} from "../../../../utlis";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import {actionGetAnnouncements, actionCreateAnnouncementAsRead} from "../../../../actions/user/announcement.actions";
import Badge from "react-bootstrap/Badge";

class UserAnnouncements extends React.Component {

    componentDidMount() {
        const {actionGetAnnouncements, loggedInUser} = this.props;
        actionGetAnnouncements(loggedInUser._id);
    }

    onViewAnnouncementDetails = (announcementId) => {
        this.props.actionCreateAnnouncementAsRead(announcementId)
        this.props.navigate(`/user/announcements/view?id=${announcementId}`);
    };

    renderColumns = () => {
        const {lang, loggedInUser} = this.props;
        return [
            {
                name: lang.Title,
                selector: 'title',
                sortable: true,
                cell: row => (
                    <>
                        {row.isDeleted ? 
                            <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.title} </div>
                            :
                            (row.markAsRead.some(asread => asread.user === loggedInUser._id)) ? 
                            <div style={{ color: '#9f9f9f' }} > {row.title} </div>
                            :
                            <div style={{ color: 'black' }} > {row.title} </div>

                        }
                    </>
                )
            },
            {
                name: lang.FormDate,
                selector: 'fromDate',
                sortable: true,
                cell: row => (
                    <>
                        {row.isDeleted ? 
                            <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {convertDateToCustomFormat(row.fromDate)} </div>
                            :
                            (row.markAsRead.some(asread => asread.user === loggedInUser._id)) ? 
                            <div style={{ color: '#9f9f9f' }} > {convertDateToCustomFormat(row.fromDate)} </div>
                            :
                            <div style={{ color: 'black' }} > {convertDateToCustomFormat(row.fromDate)} </div>

                        }
                    </> 
                )
            },
            {
                name: lang.ToDate,
                selector: 'tillDate',
                sortable: true,
                cell: row => (
                    <>
                        {row.isDeleted ? 
                            <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {convertDateToCustomFormat(row.tillDate)} </div>
                            :
                            (row.markAsRead.some(asread => asread.user === loggedInUser._id)) ? 
                            <div style={{ color: '#9f9f9f' }} > {convertDateToCustomFormat(row.tillDate)} </div>
                            :
                            <div style={{ color: 'black' }} > {convertDateToCustomFormat(row.tillDate)} </div>

                        }
                    </> 
                )
            },
            {
                name: lang.AckStatus,
                cell: row => {
                    if (row.acknowledge.some(ack => ack.user === loggedInUser._id)) {
                        return <h6><Badge style={{ textDecoration : row.isDeleted ? 'line-through' : 'none'}} variant={"success"}>{lang.Acknowledged}</Badge></h6>
                    } else {
                        return <h6><Badge style={{ textDecoration : row.isDeleted ? 'line-through' : 'none'}} variant={"secondary"}>{lang.Unacknowledged}</Badge></h6>
                    }
                }
            },
            {
                name: lang.Actions,
                button: true,
                cell: row => (
                        <>
                            {row.isDeleted === false &&
                                <i className="fas fa-eye cursor-pointer mr-1 text-info"
                                    onClick={() => this.onViewAnnouncementDetails(row._id)}/>
                            }
                        </>
                )
                        
            }
        ];
    };

    render() {
        const {announcements, lang} = this.props;

        return (
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllAnnouncements}
                            columns={this.renderColumns()}
                            data={announcements}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({user: {announcementReducer}, authentication, langReducer}) => ({
    announcements: announcementReducer.announcements,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetAnnouncements, actionCreateAnnouncementAsRead}))(UserAnnouncements);
