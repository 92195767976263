import React, {useEffect, useState} from 'react';
import {PendingChangeRequestsList} from "./pending-change-requests-list";
import {useSelector} from "react-redux";
import VerifiedAttendanceRecords from "./verifications";
import PendingAckAttendanceRecords from "./pending-acknowledgements";

export const SubsAttendanceList = (props) => {
    console.log(props,"this")
    const {lang} = useSelector(state => state.langReducer);
    const {attendanceHistory} = useSelector(state => state.user.attendanceReducer);

    const [pendingChangeRequests, setPendingChangeRequests] = useState([]);

    useEffect(() => {

        if (attendanceHistory && attendanceHistory.length > 0) {
            const filteredPendingCRs = attendanceHistory.filter(attendance => attendance.changeRequest?.lastStatus === 'pending' || attendance.BTchangeRequest?.status === 'pending');
            setPendingChangeRequests(filteredPendingCRs);
        }

    }, [attendanceHistory]);

    return (
        <div>
            <PendingChangeRequestsList data={pendingChangeRequests} navigateToAttendanceDetail={(row) => props.navigate(`/user/attendance/view/${row._id}`)}/>
            <hr/>

            <PendingAckAttendanceRecords/>
            <hr/>

            <VerifiedAttendanceRecords status={'pending'} title={lang.PendingAttendanceVerifications}/>
            <hr/>
            <VerifiedAttendanceRecords status={'verified'} title={lang.VerifiedAttendanceRecords}/>
        </div>
    );
};
