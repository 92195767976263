import React, {useState} from 'react';
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";
import {ViewAcknowledgeModal} from "./view-acknowledge-modal";

export const AcknowledgementReport = (props) => {
    const {users, content: {acknowledge = []}, lang} = props;
    const [showModal, setShowModal] = useState(false);
    const [viewAck, setViewAck] = useState({});

    const dataTableColumns = [
        {
            name: lang.EmployeeName,
            selector: 'name',
            sortable: true,
        },
        {
            name: lang.AckStatus,
            sortable: true,
            cell: row => (
                acknowledge.some(ack => ack.user === row.id) ?
                    <h6><Badge variant="success">{lang.Acknowledged}</Badge></h6> :
                    <h6><Badge variant="secondary">{lang.Unacknowledged}</Badge></h6>
            )
        },
        {
            name: lang.Actions,
            cell: row => (
                acknowledge.some(ack => ack.user === row.id) &&
                <i className="far fa-eye cursor-pointer text-info" onClick={() => {
                    const found = acknowledge.find(ack => ack.user === row.id);
                    setViewAck(found);
                    setShowModal(true)
                }}/>
            )
        }
    ];

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <DataTable
                title={lang.EmployeeAcknowledgmentsReport}
                columns={dataTableColumns}
                data={users}
                pagination={true}
            />
            <ViewAcknowledgeModal showModal={showModal} handleCloseModal={handleCloseModal} data={viewAck} lang={lang}/>
        </>
    );
};
