import React from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {convertDateTimeToCustomFormat} from "../../../../../../utlis";
import {
    createAttendanceChangeRequest,
    updateAttendanceChangeRequest, updateChangeRequestComment
} from "../../../../../../actions/user/attendance.actions";
import Badge from "react-bootstrap/Badge";
import {SupervisorCommentControl} from "../supervisor-comment-control";
import Card from "react-bootstrap/Card";
import {UserCommentControl} from "../user-comment-control";

class AttendanceChangeRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canSupervisorPostComment: false,
            canUserPostComment: false,
            status: 'approved',
            editingComment: null
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attendanceDetail.user !== this.props.attendanceDetail.user) {
            this.checkSupervisorAuthorization();
            this.checkUserAuthorization();
        }
        if (prevProps.attendanceDetail !== this.props.attendanceDetail) {
            this.checkSupervisorAuthorization();
            this.checkUserAuthorization();
        }

        if (prevState.editingComment !== this.state.editingComment) {
            this.checkUserAuthorization();
            this.checkSupervisorAuthorization();
        }
    }

    checkSupervisorAuthorization = () => {
        const {attendanceDetail, userOrg} = this.props;
        let isSupervisorOn = userOrg.otSupervisorOn.some(userId => userId === attendanceDetail.user?._id);
        const can = !!(isSupervisorOn && (attendanceDetail.changeRequest?.lastStatus === 'pending' || this.state.editingComment));
        this.setState({canSupervisorPostComment: can})
    };

    checkUserAuthorization = () => {
        const {attendanceDetail, loggedInUser} = this.props;
        const can = !!(loggedInUser._id === attendanceDetail.user?._id && (attendanceDetail.changeRequest?.lastStatus !== 'pending' || this.state.editingComment));
        this.setState({canUserPostComment: can});
    };


    handlePostResponse = async (data) => {
        const {attendanceDetail} = this.props;
        if (this.state.editingComment) {
            await this.props.updateChangeRequestComment({
                attendanceId: attendanceDetail._id,
                comment: {...data}
            });
            this.setState({editingComment: null});
        } else {
            await this.props.updateAttendanceChangeRequest(data, attendanceDetail.changeRequest._id);
        }
    };


    render() {
        const {editingComment} = this.state;
        const {attendanceDetail, loggedInUser} = this.props;
        return (
            <>
                {attendanceDetail.changeRequest && (
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Title>Change request
                                for {attendanceDetail?.user?.firstName} {attendanceDetail?.user?.lastName}</Card.Title>
                            <table className="w-100 table table-sm">
                                <thead className="border-top-0">
                                <tr>
                                    <th/>
                                    <th>Status</th>
                                    <th>Name</th>
                                    <th>PunchIn Time</th>
                                    <th>PunchOut Time</th>
                                    <th>Comment</th>
                                    <th>Post Date Time</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tr>
                                    <td>0</td>
                                    <td><span className="badge badge-secondary">Actual</span></td>
                                    <td>{attendanceDetail.user.userName}</td>
                                    <td>{convertDateTimeToCustomFormat(attendanceDetail.punchInDateTime)}</td>
                                    <td>{convertDateTimeToCustomFormat(attendanceDetail.punchOutDateTime)}</td>
                                    <td>-------</td>
                                    <td>{convertDateTimeToCustomFormat(attendanceDetail.createdAt)}</td>
                                    <td/>
                                </tr>
                                <tbody>
                                {attendanceDetail?.changeRequest?.comments?.map((obj, index) => (
                                    <tr key={obj._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {obj.status === 'pending' && <Badge variant="warning">Pending</Badge>}
                                            {obj.status === 'approved' && <Badge variant="success">Approved</Badge>}
                                            {obj.status === 'modified' && <Badge variant="info">Modified</Badge>}
                                            {obj.status === 'rejected' && <Badge variant="danger">Rejected</Badge>}
                                        </td>
                                        <td>{obj.user.userName}</td>
                                        <td>{convertDateTimeToCustomFormat(obj.punchInDateTime)}</td>
                                        <td>{obj.punchOutDateTime ? convertDateTimeToCustomFormat(obj.punchOutDateTime) : '----'}</td>
                                        <td>{obj.comment}</td>
                                        <td>{convertDateTimeToCustomFormat(obj.date)}</td>
                                        <td>
                                            {!editingComment && attendanceDetail?.changeRequest?.comments.length - 1 === index && (
                                                obj.user._id === loggedInUser._id &&

                                                <Button variant="primary" size="sm"
                                                        onClick={() => this.setState({editingComment: obj})}>Edit</Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            {this.state.canUserPostComment &&
                            <UserCommentControl handlePostResponse={this.handlePostResponse}
                                                editingComment={this.state.editingComment}/>}

                            {this.state.canSupervisorPostComment &&
                            <SupervisorCommentControl handleSuperVisorResponse={this.handlePostResponse}
                                                      editingComment={this.state.editingComment}/>}

                        </Card.Body>
                    </Card>
                )}
            </>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer}, organizationReducer, authentication, langReducer}) => ({
    attendanceDetail: attendanceReducer.attendanceDetail,
    userOrg: organizationReducer.selectedOrganization,
    loggedInUser: authentication.loggedInUser,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {
    createAttendanceChangeRequest,
    updateAttendanceChangeRequest,
    updateChangeRequestComment
})(AttendanceChangeRequest);
