import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {convertDateTimeToCustomFormat, calculateTimeDiff, convertDateToCustomFormat} from "../../../../../utlis";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {API_URL} from "../../../../../config";
import {Accordion, Card} from "react-bootstrap";

import {AttendanceImage} from "./attendance-image";
import {ModalImage} from "../../../../shared/modal-image";
import {AckRejectComment} from "./attendance-ack-reject-comment";

export const AttendanceAcknowledgment = (props) => {

    const {showModal, handleModalClose, lang, attendance, canAcknowledge} = props;
    const {user} = attendance;
    const [showModalImage, setShowModalImage] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [modalImageCaption, setModalImageCaption] = useState('');
    const [isAckCompleted, setIsAckCompleted] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);

    useEffect(() => {
        if (attendance.punchInAck && attendance.punchOutAck) {
            if (attendance.breakTime.length) {
                const complete = attendance.breakTime.some(breakTime => breakTime.startAck && breakTime.endAck);
                setIsAckCompleted(complete);
            } else {
                setIsAckCompleted(true)
            }
        } else {
            setIsAckCompleted(false);
        }

    }, [attendance]);


    const getPunchInDateTime = () => {
        if (attendance?.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest.adjustedTime.punchInDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance?.punchInDateTime);
        }
    };

    const getPunchOutDateTime = () => {
        if (attendance?.ifLate) {
            return convertDateTimeToCustomFormat(attendance?.changeRequest?.adjustedTime.punchOutDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendance?.punchOutDateTime);
        }
    };

    const handleAttendanceAcknowledge = (statusFor, approved) => {
        props.handleSubmitAcknowledgement(attendance._id, {[statusFor]: approved});
    };

    const handleBreakImageAcknowledge = (breakId, statusFor, approved) => {
        const data = {id: breakId, [statusFor]: approved};
        props.handleSubmitAcknowledgement(attendance._id, {breaks: data});
    };

    const onClickImage = (imgUrl, caption) => {
        setModalImageUrl(imgUrl);
        setModalImageCaption(caption);
        setShowModalImage(true);
    };

    return (
        <Modal show={showModal} onHide={handleModalClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {lang.AttendanceDetails}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalImage show={showModalImage} imageUrl={modalImageUrl}
                            caption={modalImageCaption} handleClose={() => setShowModalImage(false)}/>
                <Row className="form-group">
                    <Col md={4}>
                        <label>Employee Username</label>
                        <input className="form-control" value={`${user?.userName}`} readOnly/>
                    </Col>
                    <Col md={4}>
                        <label>Employee Name</label>
                        <input className="form-control" value={`${user?.firstName} ${user?.lastName}`} readOnly/>
                    </Col>
                    <Col md={4}>
                        <label>Attendance Date</label>
                        <input className="form-control"
                               value={convertDateToCustomFormat(attendance.createdAt)} readOnly/>
                    </Col>
                </Row>

                <Row className="form-group">
                    <Col md={6}>
                        <label>PunchIn Time</label>
                        <input className="form-control"
                               value={getPunchInDateTime()}
                               readOnly/>
                        <label>PunchIn Comment</label>
                        <input className="form-control"
                               value={attendance.punchInComment}
                               readOnly/>

                        <AttendanceImage
                            imgUrl={attendance.punchInImage}
                            acknowledged={attendance.punchInAck}
                            handleImageClick={() => onClickImage(`${attendance.punchInImage}`, 'Punch-In Image')}
                            handleAttendanceAcknowledge={(approved) => handleAttendanceAcknowledge('punchInAck', approved)}
                            canAcknowledge={canAcknowledge}
                            imageFor="Punch-In"
                        />

                    </Col>
                    <Col md={6}>
                        <label>PunchOut Time</label>
                        <input className="form-control" readOnly
                               value={attendance?.punchOutDateTime ? getPunchOutDateTime() : '----'}/>

                        <label>PunchOut Comment</label>
                        <input className="form-control"
                               value={attendance.punchOutComment}
                               readOnly/>
                        <AttendanceImage
                            imgUrl={attendance.punchOutImage}
                            acknowledged={attendance.punchOutAck}
                            handleImageClick={() => onClickImage(`${API_URL}/${attendance.punchOutImage}`, 'Punch-Out Image')}
                            handleAttendanceAcknowledge={(approved) => handleAttendanceAcknowledge('punchOutAck', approved)}
                            canAcknowledge={canAcknowledge}
                            imageFor="Punch-Out"
                        />

                    </Col>
                </Row>

                <div className="d-flex px-4 mt-5">
                    <span style={{width: "20px"}}>#</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.BreakTime}</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.ResumeTime}</span>
                    <span className="font-weight-bold" style={{width: "33%"}}>{lang.TotalTime}</span>
                </div>

                {attendance && attendance.breakTime?.map((breakTime, index) => (
                    <Accordion key={breakTime._id}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="cursor-pointer">
                                <div className="d-flex">
                                    <span style={{width: "20px"}}>{index + 1}</span>
                                    <span style={{width: "33%"}}>
                                        {convertDateTimeToCustomFormat(breakTime.startTime)}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? convertDateTimeToCustomFormat(breakTime.endTime) : '----'}
                                    </span>
                                    <span style={{width: "33%"}}>
                                        {breakTime.endTime ? calculateTimeDiff(breakTime.endTime, breakTime.startTime) : '----'}
                                    </span>
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row className="py-3">
                                        <Col md={{span: 6}}>
                                            <span>Break #{index + 1} image</span>
                                        </Col>
                                        <Col md={1}/>
                                        <Col md={4}>
                                            <span>Resume #{index + 1} image</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span: 6}}>
                                            <div className="position-relative">
                                                <input className="form-control"
                                                    value={breakTime.startComment}
                                                    readOnly/>
                                            </div>
                                            <AttendanceImage
                                                imgUrl={breakTime.startImage}
                                                acknowledged={breakTime.startAck}
                                                handleImageClick={() => onClickImage(`${breakTime.startImage}`, `Break #${index + 1} image`)}
                                                handleAttendanceAcknowledge={(approved) => handleBreakImageAcknowledge(breakTime._id, 'startAck', approved)}
                                                canAcknowledge={canAcknowledge}
                                                imageFor="Start Break"
                                            />

                                        </Col>
                                        <Col md={6}>

                                        <div className="position-relative">
                                                <input className="form-control"
                                                    value={breakTime.endComment}
                                                    readOnly/>
                                            </div>
                                            <AttendanceImage
                                                imgUrl={breakTime.endImage}
                                                acknowledged={breakTime.endAck}
                                                handleImageClick={() => onClickImage(`${breakTime.endImage}`, `Resume #${index + 1} image`)}
                                                handleAttendanceAcknowledge={(approved) => handleBreakImageAcknowledge(breakTime._id, 'endAck', approved)}
                                                canAcknowledge={canAcknowledge}
                                                imageFor="End Break"
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                ))}
            </Modal.Body>
            <Modal.Footer>
                {!isAckCompleted && (
                    <>
                        <Button variant="success" size="sm"
                                onClick={() => props.handleBulkAcknowledgement(attendance._id, {status: true})}>
                            Acknowledge All
                        </Button>
                        <Button variant="danger" size="sm"
                                onClick={() => setShowCommentModal(true)}>
                            Reject All</Button>
                        <AckRejectComment
                            show={showCommentModal}
                            handleClose={() => setShowCommentModal(false)}
                            handleSubmit={(rejectComment) => props.handleBulkAcknowledgement(attendance._id, {
                                status: false,
                                rejectComment
                            })}/>
                    </>
                )}
                <Button variant="secondary" size="sm" onClick={handleModalClose}>
                    {lang.Close}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
