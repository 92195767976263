import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const AckRejectComment = (props) => {
    const {show, handleClose, handleSubmit} = props;
    const [rejectComment, setRejectComment] = useState('');

    const onSubmitRejectComment = () => {
        handleSubmit(rejectComment);
        setRejectComment('');
    };

    return (<Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
            <div className="form-group">
                <label className="font-weight-bold">{props.imageFor} Acknowledgement Reject Reason</label>
                <input className="form-control form-control-sm"
                       onChange={event => setRejectComment(event.target.value)}/>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" size="sm" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" size="sm" onClick={onSubmitRejectComment}>
                Submit
            </Button>
        </Modal.Footer>
    </Modal>)
};
