import axios from '../axiosConfig';
import {toast} from 'react-toastify';

export const getAllApps = async () => {
    try {
        const response = await axios.get(`apps`);
        return response.data;
    } catch (err) {
        toast.error('Error occurred while get apps. Please try again');
    }
};
