import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { updateEmployeePassword } from "../../../../actions/admin/employee.actions";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");

  const onClickUpdatePassword = async () => {
    try {
      await updateEmployeePassword(props.employeeId, password);
    } catch (err) {}
  };

  return (
    <>
      <div className="row  form-group">
        <div className="col-md-3 form-group">
          <label>Change Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="New password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="col-md-3 pt-4">
          <Button
            className="mt-2"
            size="sm"
            onClick={onClickUpdatePassword}
            disabled={!password.length}
          >
            Update password
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
