import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {userConstants} from "../../../../constants";
import {useSelector} from "react-redux";
// import Camera from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';

import Camera from "./Camera";
const enableCamera = process.env.REACT_APP_CAMERA_ENABLE;
//console.log("db: enableCamera:", enableCamera)
export const DashboardModal = (props) => {
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [comments, setComments] = useState('');
    const [isLoading, setLoading] = useState(false);
    const {outlets} = useSelector(state => state.user.outletReducer);
    const {showModal, handleModalClose, openedFor, handleFileChange, lang} = props;

    useEffect(() => {
        if (outlets && outlets.length) {
            setSelectedOutlet(outlets[0]._id);
        }
    }, [outlets]);

    const handleImageChange = (event) => {
        // console.log("imge change", event);
        setFile(event);
        setFileError(false);
        handleFileChange(event);
    };

    const handleSubmit = async () => {
        if (enableCamera) {
            if (!file) {
                setFileError(true);
                return false;
            }
            setLoading(true);            
        }            
        
        const data = {openedFor, selectedOutlet, comments};
        await props.handleSubmit(data);
        setLoading(false);
    };

    const handleTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        //console.log('takePhoto');
    }

    return (
        <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {openedFor === userConstants.PUNCH_IN && <span>{lang.PunchInTo} </span>}
                    {openedFor === userConstants.PUNCH_OUT && <span>{lang.PunchOutTo} </span>}
                    {openedFor === userConstants.BREAK_START && <span>{lang.BreakFrom}</span>}
                    {openedFor === userConstants.BREAK_END && <span>{lang.ResumeTo} </span>}
                    Attendance
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {openedFor === userConstants.PUNCH_IN &&
                <div className="form-group">
                    <label>Outlet</label>
                    <select className="form-control" onChange={e => setSelectedOutlet(e.target.value)}>
                        {outlets && outlets.map(outlet => (
                            <option key={outlet._id} value={outlet._id}>{outlet.name}</option>
                        ))}
                    </select>
                </div>
                }
                <div className="form-group">
                
                  { enableCamera && <Camera 
                    handleFileChange ={handleImageChange}
                  /> }
                 {fileError && <small className="text-danger">Picture is required</small>}
                 
                    {/* <Camera
                    isFullscreen={false}
                    idealResolution = {{width: 400, height: 400}}
                    onTakePhoto={(dataUri) => {
                    handleTakePhoto(dataUri);
                    }}
                    /> */}
                </div>
                {/* <div className="form-group">
                    <label className="font-weight-bold">{lang.UploadImage}</label>
                    <input className="w-100" type="file" accept="image/*;capture=camera" onChange={handleImageChange}/>
                    {fileError && <small className="text-danger">Picture is required</small>}
                </div> */}

                {(openedFor === userConstants.PUNCH_IN || openedFor === userConstants.PUNCH_OUT || openedFor === userConstants.BREAK_START || openedFor === userConstants.BREAK_END) && (
                    <div className="form-group">
                        <label>Comments</label>
                        <input className="form-control" name="comments"
                               onChange={event => setComments(event.target.value)}/>
                    </div>
                )}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleModalClose}>
                    {lang.Close}
                </Button>
                <Button variant="primary" size="sm" onClick={handleSubmit} disabled={isLoading}>
                    {lang.Proceed}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
