import React, {Component} from "react";
import {Card, Container, FormGroup} from "react-bootstrap";
import Toggle from "react-toggle";
import {Formik} from "formik";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
// import {toFormData} from "../../../../utlis";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {createLeaveType, updateLeaveType, getAllLeaveTypes} from "../../../../actions/admin/leave.management";
import Badge from "react-bootstrap/Badge";
import DataTable from "react-data-table-component";


class CreateLeaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formikState: this.getFormikState()
        }
    }

    componentDidMount() {
        this.props.getAllLeaveTypes();
    }


    handleSubmit = async (values, action) => {
        const {createLeaveType, updateLeaveType, leaveType} = this.props;
        try {
            if (leaveType?._id) {
                const obj = {
                    ...values,
                    _id: leaveType._id
                };
                // const formData = toFormData(obj);
                await updateLeaveType(obj._id, obj);
            } else {
                // const formData = toFormData(values);
                await createLeaveType(values);
                action.resetForm();
            }
            this.props.getAllLeaveTypes();
            this.props.handleClose();
            toast.success("Record is saved");
        } catch (e) {

        }
    };


    getValidationSchema = () => {
        return yup.object({
            name: yup.string().required(),
            shortId: yup.string().required(),
            color: yup.string().required()
        });
    };

    getFormikState = () => {
        const {data} = this.props;
        return {
            name: data?.name || '',
            shortId: data?.shortId || '',
            color: data?.color || '',
            paid: data?.paid,
            isActive: data?.isActive || true,
            isDeleted: data?.isDeleted
        }
    };


    renderColumns = () => {
        return [
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Short Id',
                selector: 'shortId',
                sortable: true,
            }, {
                name: 'Color',
                selector: 'color',
                // sortable: true,
            },
            {
                name: 'Paid',
                selector: 'paid',
                sortable: true,
                cell: row => (
                    <h6>  {row.paid === true ? <Badge variant="success">Active</Badge> :
                        <Badge variant="danger">Inactive</Badge>}
                    </h6>
                )
            },
            {
                name: 'Active',
                selector: 'isActive',
                // button: true,
                cell: row => (
                    <h6>  {row.isActive === true ? <Badge variant="success">Active</Badge> :
                        <Badge variant="danger">Inactive</Badge>}
                    </h6>
                )
            }
        ];
    };


    render() {
        const {formikState} = this.state;
        return (
            <>
                <Formik
                    validationSchema={this.getValidationSchema()}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{...formikState}}>
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                          setFieldValue
                      }) => (
                        <Container fluid>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Create Leave Types
                                    </Card.Title>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Row>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label className="d-block">Leave Type</Form.Label>
                                                <Form.Control value={values.name} type="text" onChange={handleChange}
                                                              name="name"/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.name}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="4">
                                                <Form.Label className="d-block">Short Id</Form.Label>
                                                <Form.Control value={values.shortId} type="text" onChange={handleChange}
                                                              name="shortId"/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.shortId}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>

                                            <Form.Group as={Col} md="2" className="ml-3">
                                                <Form.Label className="d-block">Color</Form.Label>
                                                <Form.Control value={values.color} onChange={handleChange} type="color"
                                                              name="color"/>
                                                <Form.Control.Feedback
                                                    type="invalid">{errors.color}</Form.Control.Feedback>
                                            </Form.Group>

                                            <div className=" mx-5 mt-4">
                                                <span className="mr-3">Paid</span>
                                                <Toggle
                                                    defaultChecked={values.paid}
                                                    onChange={(e) => setFieldValue('paid', e.target.checked)}
                                                />
                                            </div>

                                            <div className=" mt-4">
                                                <span className="mb-3 mr-3">Active</span>
                                                <Toggle
                                                    checked={values.isActive}
                                                    onChange={(e) => setFieldValue('isActive', e.target.checked)}/>
                                            </div>

                                        </Form.Row>

                                        <Button size="sm" type="submit" className="ml-3 mr-4 mt-4">Save </Button>
                                        <Button variant="secondary" size="sm"  onClick={this.props.handleClose} className="mt-4">Close</Button>

                                    </Form>
                                </Card.Body>
                            </Card>
                        </Container>
                    )}
                </Formik>

                <Container fluid className="pt-4">
                    <Card>
                        <Card.Body>
                            <DataTable
                                title="Leave Types"
                                columns={this.renderColumns()}
                                data={this.props.leaveTypes}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }
}

const mapStateToProps = ({admin: {leaveReducer}}) => ({
    leaveTypes: leaveReducer.leaveTypes,
    leaveType: leaveReducer.leaveType,
});

export default connect(mapStateToProps, ({
    createLeaveType,
    updateLeaveType,
    getAllLeaveTypes
}))(CreateLeaves);
