import React from 'react';
import {API_URL} from "../../../../../../config";
import styles from "../index.module.css";
import Button from "react-bootstrap/Button";
import {convertDateTimeToCustomFormat} from "../../../../../../utlis";

export const AttendanceImage = (props) => {
    const {imgUrl, acknowledged, handleImageClick, handleAttendanceAcknowledge, canAcknowledge} = props;

    return (
        <div>
            <div className="position-relative">
                <img src={`${imgUrl}`}
                     className="img-fluid img-thumbnail mt-2"
                     onClick={() => handleImageClick(`${imgUrl}`)}
                     alt=''/>
                {acknowledged && (
                    <div className={styles.imgAckStatusOverlay}
                         onClick={() => handleImageClick(`${imgUrl}`)}>
                        {acknowledged.ack ?
                            <h3><span className="badge badge-success">Acknowledged</span></h3> :
                            <h3><span className="badge badge-danger">Rejected</span></h3>}
                    </div>
                )}
            </div>

            {!acknowledged && canAcknowledge && (
                <div className="text-center mt-2">
                    <Button variant="success" size="sm" className="mr-1"
                            onClick={() => handleAttendanceAcknowledge(true)}>
                        <i className="fas fa-check-double"/> Acknowledge
                    </Button>
                    <Button variant="danger" size="sm"
                            onClick={() => handleAttendanceAcknowledge(false)}>
                        <i className="fas fa-times"/> Reject
                    </Button>
                </div>
            )}

            {acknowledged && (
                <div className="text-center">
                    {acknowledged.ack ? "Approved by" : "Rejected by"}
                    <span className="text-info font-weight-bold ml-1">
                        {acknowledged?.user.userName}
                    </span>
                    <small className="d-block">
                        {convertDateTimeToCustomFormat(acknowledged.dateTime)}
                    </small>
                </div>
            )}
        </div>
    );
};
