import React, {useEffect, useState} from 'react';
import Select from "react-select";

export const SelectOutlets = (props) => {

    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    const {outlets = [], values = [], handleSelectChange} = props;

    useEffect(() => {
        const options = outlets.map(outlet => ({
            value: outlet._id,
            label: outlet.name
        }));

        setSelectOptions(options);

        const result = outlets.filter(outlet => values.some(outletId => outletId === outlet._id))
            .map(outlet => ({
                value: outlet._id,
                label: outlet.name
            }));

        setSelectedValues(result);

    }, [outlets, values]);

    const handleChange = (selected) => {
        const outletIds = selected ? selected.map(outlet => outlet.value) : [];
        handleSelectChange(outletIds);
    };

    return (
        <Select
            options={selectOptions}
            value={selectedValues}
            onChange={handleChange}
            isSearchable
            isMulti
        />
    )
};
