import React, {useEffect, useState} from 'react';
import Button from "react-bootstrap/Button";
import {userConstants} from "../../../../../constants";
import {secondsToHMS} from "../../../../../utlis";
import {useSelector} from "react-redux";

export const PunchAttendance = (props) => {

    const [timer, setTimer] = useState('00:00:00');
    const [isBreakActive, setBreakActive] = useState(false);
    const {activeSession, breakTimes, handleModalShow, lang} = props;
    let elapsedSeconds = 0;

    const calculateTimeDifference = (punchInDateTime) => {
        const now = new Date();
        const elapsedMilliseconds = now - punchInDateTime;
        const elapsedDate = new Date(elapsedMilliseconds);
        const secondValue = elapsedDate.getUTCSeconds();

        const hours = String(elapsedDate.getUTCHours()).padStart(2, '0');
        const minutes = String(elapsedDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(secondValue < 0 ? 0 : secondValue).padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    };


    useEffect(() => {
        if (breakTimes.length > 0) {
            const found = breakTimes.find(br => br.startTime && !br.endTime);
            setBreakActive(!!found);
        }

        if (!props?.tempState?.isProceed) {
            const punchInDateTime = activeSession?.punchInDateTime ? new Date(activeSession.punchInDateTime) : null;
    
            if (punchInDateTime) {
                // Immediately set the timer value
                setTimer(calculateTimeDifference(punchInDateTime));
    
                // Interval 
                const interval = setInterval(() => {
                    setTimer(calculateTimeDifference(punchInDateTime));
                }, 1000);
    
                return () => clearInterval(interval);
            } else {
                setTimer('00:00:00');
            }
        } else {
            console.log("DEBUG", elapsedSeconds)
            const interval = setInterval(() => {
            const newSeconds = elapsedSeconds + 1;
            const hours = String(Math.floor(newSeconds / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((newSeconds % 3600) / 60)).padStart(2, '0');
            const seconds = String(newSeconds % 60).padStart(2, '0');
            setTimer(`${hours}:${minutes}:${seconds}`);

            elapsedSeconds = newSeconds;
            }, 1000);
    
            return () => clearInterval(interval);
        }
    }, [activeSession, breakTimes]);


    return (
        <div className="d-flex bg-info text-white shadow ">
                            <span className="px-4 my-auto" style={{fontSize: '45px'}}>
                                <i className="far fa-clock"/>
                            </span>
            <div className="py-2 flex-grow-1">
                {activeSession ? <span>{lang.PUNCH_OUT_FROM_THIS_SHIFT}</span> :
                    <span>{lang.PUNCH_IN_TO_SHIFT}</span>}
                <br/>
                <span className="font-weight-bold">
                                    {timer}
                                </span>
                <div className="border border-white my-2"/>
                {
                    activeSession ?
                        <Button
                            variant="light" size="sm"
                            onClick={() => handleModalShow(userConstants.PUNCH_OUT)}
                            disabled={isBreakActive}>
                            {lang.PunchOut}</Button> :
                        <Button
                            variant="light" size="sm"
                            onClick={() => handleModalShow(userConstants.PUNCH_IN)}>
                            {lang.PunchIn}</Button>
                }
            </div>
        </div>
    );
};
