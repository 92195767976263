import {Route, Routes} from "react-router-dom";
import React from "react";
import SideBar from "./side-bar/SideBar";
import TopBar from "./top-bar/TopBar";
import './Layout.css';
import {UserRoutes} from "./user-routes";
import {AdminRoutes} from "./admin-routes";
import {connect} from "react-redux";
import {actionGetOrganizations} from "../../actions/organization.actions";
import {getAllApps} from "../../actions/user/app.actions";
import UserDashboard from "../saas-user/user/dashboard/UserDashboard";
import AccountSettings from "../saas-user/shared/account-settings";
import AppIcon from "../../assets/app.svg";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSideNavOpen: false,
            apps: [],
        };
    }

    componentDidMount() {
        const {selectedOrganization, location, navigate} = this.props;
        if (!selectedOrganization) {
            navigate("/select-organization");
        } else {
            this.props.actionGetOrganizations();
            getAllApps()
            .then((res) => {
                this.setState({
                    apps: res.data?.availableModules?.map(x => ({
                        code: x.code,
                        label: x.name,
                        link: x.link,
                        image: x.image ? x.image : AppIcon
                    }))
                })
            })
        }
    }

    toggleSidebar = () => {
        this.setState({isSideNavOpen: !this.state.isSideNavOpen});
    };

    render() {
        let jsonOrg = localStorage.getItem("selectedOrganization");
        if (jsonOrg) {
            jsonOrg = JSON.parse(jsonOrg);
        }
        const {isSideNavOpen, apps} = this.state;
        const {location, match} = this.props;
        return (
            <div id="wrapper" className={`d-flex ${isSideNavOpen ? 'toggled' : ''}`}>
                <SideBar {...this.props} className="shadow"/>

                <div id="page-content-wrapper" style={{position: 'relative'}}>

                    <TopBar toggleSidebar={this.toggleSidebar} apps={apps} {...this.props}/>

                    <div className="container-fluid mb-5">
                        {this.props.children}
                    </div>

                    <footer className="text-right bg-light border-top" style={{
                        position: 'absolute', bottom: 0, left: -1,
                        width: '100%',
                        padding: "10px 10px 10px 0"
                    }}>
                        <small className="font-weight-bold">&#169; {jsonOrg?.corpName ?? ""}</small>
                    </footer>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({organizationReducer}) => ({
    selectedOrganization: organizationReducer.selectedOrganization
});

export default connect(mapStateToProps, ({actionGetOrganizations}))(Layout);
