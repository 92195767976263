import React from "react";
import {connect} from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    createAttendanceChangeRequest,
    getAttendanceDetails,
    getBtAttendanceDetails,
    updateAttendanceChangeRequest,
    updateBtAttendanceChangeRequest,
    createAttendanceBtChangeRequest
} from "../../../../../actions/user/attendance.actions";
import {SupervisorBtCommentControl} from './supervisor-comment-control/index';
import {convertDateTimeToCustomFormat, convertDateToCustomFormat} from "../../../../../utlis";
import AttendanceChangeRequest from "./change-request";
import BreakTimeChangeRequest from "./supervisor-comment-control/index"
import {ChangeTimes, ChangeBreakTime} from "./change-times";
import Button from "react-bootstrap/Button";
import {forEach} from "react-bootstrap/cjs/ElementChildren";
import Badge from "react-bootstrap/Badge";

class ViewAttendanceDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isCreatingChangeRequest: false,
            isCreatingBtChangeRequest: false,
            status: 'approved',
            Comment: '',
            canUserPostComment: false,
            canSupervisorPostComment: false,
            currentbreakTimeId: "",
            disabled: false
        };
    }

    componentDidMount() {
        const { params } = this.props;

        this.props.getAttendanceDetails(params.attendanceId);

        this.props.getBtAttendanceDetails(params.attendanceId);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.attendanceDetail.user !== this.props.attendanceDetail.user) {
            this.checkSupervisorAuthorization();
        }
        if (prevProps.attendanceDetail !== this.props.attendanceDetail) {
            this.checkSupervisorAuthorization();
        }

        if (prevState.editingComment !== this.state.editingComment) {
            this.checkSupervisorAuthorization();
        }


        if (prevProps.attendanceDetail.user !== this.props.attendanceDetail.user) {
            this.checkUserAuthorization();
        }
    }

    checkUserAuthorization = () => {
        const {attendanceDetail, loggedInUser} = this.props;
        const can = loggedInUser._id === attendanceDetail.user?._id;
        this.setState({canUserPostComment: can});
    };
    checkSupervisorAuthorization = () => {
        const {attendanceDetail, userOrg} = this.props;
        let isSupervisorOn = userOrg.otSupervisorOn.some(userId => userId === attendanceDetail.user?._id);
        if (isSupervisorOn) {
            this.setState({canSupervisorPostComment: true})
        }
    };

    getPunchInDateTime = () => {
        const {attendanceDetail} = this.props;
        if (attendanceDetail.ifLate) {
            return convertDateTimeToCustomFormat(attendanceDetail?.changeRequest?.adjustedTime?.punchInDateTime);

        } else {
            return convertDateTimeToCustomFormat(attendanceDetail.punchInDateTime);

        }
    };

    getPunchOutDateTime = () => {

        const {attendanceDetail} = this.props;


        if (attendanceDetail.ifLate) {
            return convertDateTimeToCustomFormat(attendanceDetail.changeRequest?.adjustedTime?.punchOutDateTime);
        } else {
            return convertDateTimeToCustomFormat(attendanceDetail.punchOutDateTime);
        }
    };
    getStartBreakTime = () => {
        const {attendanceDetail} = this.props;
        return convertDateTimeToCustomFormat(attendanceDetail?.BTchangeRequest.requestTime.requestedBreakStartTime);

    };
    getEndBreakTime = () => {
        const {attendanceDetail} = this.props;
        return convertDateTimeToCustomFormat(attendanceDetail?.BTchangeRequest.requestTime.requestedBreakEndTime);

    };

    setNewDate = () => {
        return new Date();
    }

    submitChangeTimeRequest = async (data) => {
        await this.props.createAttendanceChangeRequest(data);
        this.setState({isCreatingChangeRequest: false});
    };
    submitBtChangeRequest = async (data) => {

        if (data.status === 'approved' || data.status === 'rejected') {
            const { params } = this.props;
            data.attendance = params.attendanceId;
            await this.props.updateBtAttendanceChangeRequest(data, data.breakTimeId);
            await this.props.getAttendanceDetails(params.attendanceId);
        } else {
            data.breakTimeId = this.state.currentbreakTimeId;
            const {breakTimeDetail} = this.props;

            const foundRequest = breakTimeDetail.find(pendingRequest => {
                return pendingRequest.breakTimeId === this.state.currentbreakTimeId;
            })

            if (foundRequest) {
                await this.props.updateBtAttendanceChangeRequest(data, foundRequest._id);
            } else {
                await this.props.createAttendanceBtChangeRequest(data);
            }


            this.setState({isCreatingBtChangeRequest: false});
        }
        const { params } = this.props;
        await this.props.getBtAttendanceDetails(params.attendanceId)

    }
    setDisable = (currentBreakId) => {
        this.setState({isCreatingBtChangeRequest: true, currentbreakTimeId: currentBreakId});
        const {attendanceDetail} = this.props;
        attendanceDetail.breakTime.forEach(breakTime => {
            if (!breakTime.endTime) {
                if (breakTime._id === currentBreakId) {
                    this.setState({disabled: true});
                } else {
                    this.setState({disabled: false});

                }
            } else {
                this.setState({disabled: false});
            }
        })
    }

    render() {
        const {attendanceDetail, breakTimeDetail} = this.props;

        const {user = {}} = attendanceDetail;
        return (
            <>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Employee Details</Card.Title>
                        <hr/>
                        <Row className="form-group">
                            <Col md={3}>
                                <label>Employee Username</label>
                                <input className="form-control" value={`${user.userName}`} readOnly/>
                            </Col>
                            <Col md={3}>
                                <label>Employee Name</label>
                                <input className="form-control" value={`${user.firstName} ${user.lastName}`} readOnly/>
                            </Col>
                            <Col md={3}>
                                <label>Attendance Date</label>
                                <input className="form-control"
                                       value={convertDateToCustomFormat(attendanceDetail.createdAt)} readOnly/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Punch In / Punch Out Details</Card.Title>
                        <hr/>

                        <Row className="form-group">
                            <Col md={3}>
                                <label>PunchIn Time</label>
                                <input className="form-control"
                                       value={this.getPunchInDateTime()}
                                       readOnly/>
                            </Col>
                            <Col md={3}>
                                <label>PunchIn Comment</label>
                                <input className="form-control" readOnly 
                                       value={attendanceDetail.punchInComment ? attendanceDetail.punchInComment : '----'}/>
                            </Col>
                        </Row>
                        
                        <Row className="form-group">
                            <Col md={3}>
                                <label>PunchOut Time</label>
                                <input className="form-control" readOnly format="dd-MMM-yyyy h:mm:ss"
                                       value={attendanceDetail.punchOutDateTime ? this.getPunchOutDateTime() : '----'}/>
                            </Col>
                            <Col md={3}>
                                <label>PunchOut Comment</label>
                                <input className="form-control" readOnly 
                                       value={attendanceDetail.punchOutComment ? attendanceDetail.punchOutComment : '----'}/>
                            </Col>
                        </Row>
                        {this.state.canUserPostComment && !attendanceDetail.changeRequest && (
                            this.state.isCreatingChangeRequest ? (
                                <ChangeTimes submitChangeTimeRequest={this.submitChangeTimeRequest}
                                             cancelChangeReq={() => this.setState({isCreatingChangeRequest: false})}/>
                            ) : <Button size='sm' onClick={() => this.setState({isCreatingChangeRequest: true})}>
                                Create Change Request</Button>
                        )}

                    </Card.Body>
                </Card>

                <AttendanceChangeRequest/>

                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Break Times</Card.Title>
                        <hr/>
                        {attendanceDetail.breakTime?.map((breakTime, index) => (
                            <Row className="form-group" key={breakTime._id}>
                                <Col md={3}>
                                    <label>{index + 1}# Break Time</label>
                                    <input className="form-control" readOnly
                                           value={convertDateTimeToCustomFormat(breakTime.startTime)}/>

                                </Col>
                                <Col md={3}>
                                    <label>{index + 1}# Break Comment</label>
                                    <input className="form-control" readOnly
                                           value={(breakTime.startComment ? breakTime.startComment : '----')}/>
                                </Col>

                                <Col md={3}>
                                    <label>{index + 1}# Resume Time</label>
                                    <input className="form-control" readOnly
                                           value={(breakTime.endTime ? convertDateTimeToCustomFormat(breakTime.endTime) : '----')}/>
                                </Col>
                                <Col md={3}>
                                    <label>{index + 1}# Resume Comment</label>
                                    <input className="form-control" readOnly
                                           value={(breakTime.endComment ? breakTime.endComment : '----')}/>
                                </Col>
                                <Col md={6}>
                                    {!this.state.canSupervisorPostComment ?
                                        <Button size='sm' style={{marginTop: "2%"}}
                                                onClick={() => this.setDisable(breakTime._id)}>
                                            Edit</Button>
                                        : null}


                                </Col>
                                <Col md={6}>
                                    {breakTimeDetail?.map(breakTimeRequest =>
                                        (breakTimeRequest?.breakTimeId === breakTime?._id ?
                                                <Card>
                                                    <table className="w-100 table table-sm">
                                                        <thead className="border-top-0">
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>Name</th>
                                                            <th>Start Time</th>
                                                            <th>End Time</th>

                                                            <th/>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        <tr>
                                                            <td>
                                                                {breakTimeRequest.status === 'pending' &&
                                                                <Badge variant="warning">Pending</Badge>}
                                                                {breakTimeRequest.status === 'approved' &&
                                                                <Badge variant="success">Approved</Badge>}
                                                                {breakTimeRequest.status === 'modified' &&
                                                                <Badge variant="info">Modified</Badge>}
                                                                {breakTimeRequest.status === 'rejected' &&
                                                                <Badge variant="danger">Rejected</Badge>}
                                                            </td>
                                                            <td>{attendanceDetail?.user?.userName}</td>
                                                            <td>{convertDateTimeToCustomFormat(breakTimeRequest.requestedBreakStartTime)}</td>
                                                            <td>{breakTimeRequest.requestedBreakEndTime ? convertDateTimeToCustomFormat(breakTimeRequest.requestedBreakEndTime) : '----'}</td>
                                                        </tr>
                                                        </tbody>


                                                    </table>
                                                    {this.state.canSupervisorPostComment && breakTimeRequest.status === 'pending' ?
                                                        <SupervisorBtCommentControl
                                                            currentBreakTimeId={breakTimeRequest._id}
                                                            submitBtChangeRequest={this.submitBtChangeRequest}/> : null}
                                                </Card> : null
                                        )
                                    )}


                                </Col>
                                {this.state.canUserPostComment && this.state.currentbreakTimeId === breakTime?._id && (
                                    this.state.isCreatingBtChangeRequest ? (
                                        <ChangeBreakTime submitBtChangeRequest={this.submitBtChangeRequest}
                                                         cancelChangeReq={() => this.setState({isCreatingBtChangeRequest: false})}
                                                         disabled={this.state.disabled}/>

                                    ) : null)}
                            </Row>
                        ))}

                    </Card.Body>
                </Card>

            </>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer}, organizationReducer, authentication, langReducer}) => ({
    attendanceDetail: attendanceReducer.attendanceDetail,
    breakTimeDetails: attendanceReducer.breakTimeDetails,
    breakTimeDetail: attendanceReducer.breakTimeDetail,
    loggedInUser: authentication.loggedInUser,
    userOrg: organizationReducer.selectedOrganization,


    lang: langReducer.lang
});

export default connect(mapStateToProps, ({
    getAttendanceDetails,
    getBtAttendanceDetails,
    updateBtAttendanceChangeRequest,
    createAttendanceChangeRequest,
    createAttendanceBtChangeRequest,
    updateAttendanceChangeRequest
}))(ViewAttendanceDetails);
