import {adminConstants} from "../../constants";

const INITIAL_STATE = {
    ARVO_Fields: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case adminConstants.ACTION_GET_CUSTOM_FIELDS:
            return {
                ...state,
                ARVO_Fields: action.payload.filter(obj => obj.isActive)
            };
        default:
            return state;
    }
}
